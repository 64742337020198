<template>
  <div>
    <div>
      <NavBar></NavBar>
    </div>
    <div class="generalContainer">
      <Header></Header>
      <div class="containerProfile">
        <h4 class="mt-3 ml-2 storeTitle">
          {{ $t("profile.editInformation") }}
          <!-- {{
            languaje == "es"
              ? "Editar Información de la Farmacia"
              : "Edit Pharmacy information"
          }} -->
        </h4>
        <div class="row justify-content-center mt-5">
          <div class="col-11 col-sm-11 col-md-6 col-lg-6 col-xl-4">
            <div class="form-group">
              <label class="form-title" for="exampleInputEmail1">
                {{ $t("profile.email") }}:
                <!-- {{ languaje == "es" ? "Correo Electrónico:" : "Email:" }} -->
              </label>
              <input
                v-model="email"
                id="email"
                type="email"
                class="storeFormInput"
                :disabled="!usuarioFarmacia.admin"
              />
            </div>
          </div>
          <div class="col-11 col-sm-11 col-md-6 col-lg-6 col-xl-4">
            <div class="form-group">
              <label class="form-title" for="exampleInputPassword1">
                {{ $t("profile.pharmacyName") }}:
                <!-- {{
                languaje == "es" ? "Nombre de Farmacia:" : "Pharmacy Name:"
              }} -->
              </label>
              <input
                v-model="nameStore"
                id="name"
                type="text"
                class="storeFormInput"
                :disabled="!usuarioFarmacia.admin"
              />
            </div>
          </div>
          <div class="col-11 col-sm-11 col-md-6 col-lg-6 col-xl-4">
            <div class="form-group">
              <label class="form-title" for="exampleInputPassword1"
                >{{ $t("profile.phone") }}:
                <!-- {{
                  languaje == "es" ? "Telefono de Farmacia:" : "Phone Pharmacy:"
                }} -->
              </label>
              <input
                v-model="phoneNumber"
                id="phone"
                type="number"
                class="storeFormInput"
                min="10"
                :disabled="!usuarioFarmacia.admin"
              />
            </div>
          </div>
          <div class="col-11 col-sm-11 col-md-6 col-lg-6 col-xl-4">
            <div class="form-group">
              <label class="form-title" for="exampleInputAddress"
                >{{ $t("profile.address") }}:
                <!-- {{
                  languaje == "es"
                    ? "Dirección de Farmacia:"
                    : "Address Pharmacy:"
                }} -->
              </label>
              <input
                v-model="address"
                id="address"
                type="text"
                class="storeFormInput"
                :disabled="!usuarioFarmacia.admin"
              />
            </div>
          </div>

          <div class="col-11 col-sm-11 col-md-4 col-lg-4 col-xl-4">
            <div class="form-group">
              <p class="form-title" for="farmaceutico">
                {{ $t("profile.admin") }}:
                <!-- {{
                  languaje == "es"
                    ? "Administrador de Farmacia:"
                    : "Administrator Pharmacy:"
                }} -->
              </p>
              <input
                v-model="farmaceutico"
                id="farmaceutico"
                type="text"
                class="storeFormInput"
                :disabled="!usuarioFarmacia.admin"
              />
            </div>
          </div>
          <div class="col-11 col-sm-11 col-md-4 col-lg-4 col-xl-4">
            <div class="form-group" id="categories-list">
              <p class="form-title" for="exampleInputPassword1">
                {{ $t("profile.city") }}:
                <!-- {{
                  languaje == "es" ? "Ciudad de Farmacia:" : "City Pharmacy:"
                }} -->
              </p>
              <input
                type="text"
                v-model="searchCity"
                placeholder="Filtre por Nombre"
                class="storeFormInput mb-2"
                v-if="changeCiudades && languaje == 'es'"
              />
              <input
                type="text"
                v-model="searchCity"
                placeholder="Filter by Name"
                class="storeFormInput mb-2"
                v-if="changeCiudades && languaje != 'es'"
              />

              <select
                v-model="city"
                class="storeFormInput"
                v-if="changeCiudades"
              >
                <option
                  v-for="(category, index) in categories"
                  :key="index"
                  :value="category.get('name')"
                  >{{ category.get("name") }}</option
                >
              </select>
              <div
                class="storeFormInput row"
                v-if="!changeCiudades"
                @click="onChangeCiudades()"
              >
                <div v-if="city != ''">
                  <div class="col col-6">{{ city }}</div>
                </div>
                <div v-else>
                  <div>
                    {{ $t("profile.selectCity") }}:
                    <!-- {{
                      languaje == "es" ? "Elija una ciudad:" : "Select a City:"
                    }} -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-11 col-sm-11 col-md-4 col-lg-4 col-xl-4"
            v-if="usuarioFarmacia.admin"
          >
            <div class="form-group" id="categories-list">
              <p class="form-title mb-2" for="exampleInputPassword1">
                {{ $t("profile.password") }}:
                <!-- {{
                  languaje == "es"
                    ? "Contraseña de Farmacia:"
                    : "Password Pharmacy:"
                }} -->
              </p>
              <input
                type="password"
                class="storeFormInput"
                v-model="password"
              />
            </div>
          </div>
          <div
            class="col-11 col-sm-11 col-md-4 col-lg-4 col-xl-4"
            v-if="usuarioFarmacia.admin"
          ></div>
          <div class="col-11 col-sm-11 col-md-4 col-lg-4 col-xl-4">
            <p class="form-title" for="exampleInputPassword1">
              {{ $t("profile.location") }}:
              <!-- {{
                languaje == "es"
                  ? "Localizacion de Farmacia:"
                  : "Location Pharmacy:"
              }} -->
            </p>
            <mapbox
              access-token="pk.eyJ1IjoicGl4bmFiaSIsImEiOiJjamhrdjg4eXMyeDk4M2RtN2twMWkyZWI3In0.u5lGsCbV-mc17DRcFviPHw"
              :map-options="{
                style: 'mapbox://styles/jrosario241/cjsuqzyev4cip1fo3cv5c3vr5',
                center: [-66.5, 18.25],
                zoom: 8
              }"
              :geolocate-control="{
                show: true,
                position: 'top-left'
              }"
              @map-load="loaded"
              @map-click="clicked"
              @geolocate-error="geolocateError"
              @geolocate-geolocate="geolocate"
            />
            <button
              class="mt-1 btn btn-danger btn-block"
              @click="removeMarkers"
            >
              {{ $t("profile.clear") }}
              <!-- {{ languaje == "es" ? "Limpiar Marcadores" : "Clean Markers" }} -->
            </button>
          </div>
        </div>
        <!-- Barra indicadora de los datos -->
        <div class="indicationBar mt-3">
          <div
            class="row justify-content-center"
            style="height:100%;align-items:center"
          >
            <div
              style="display:flex; align-items:center;padding-left:36px"
              class="col-2"
            >
              <p class="textDescription">
                {{ $t("profile.days") }}
                <!-- {{ languaje == "es" ? "DIAS" : "DAYS" }} -->
              </p>
            </div>
            <div
              style="display:flex; align-items:center;padding-left:36px"
              class="col-5"
            >
              <p class="textDescription">
                {{ $t("profile.open") }}
                <!-- {{ languaje == "es" ? "ABIERTO" : "OPEN" }} -->
              </p>
            </div>
            <div
              style="display:flex; align-items:center;padding-left:36px"
              class="col-5"
            >
              <p class="textDescription">
                {{ $t("profile.close") }}
                <!-- {{ languaje == "es" ? "CERRADO" : "CLOSE" }} -->
              </p>
            </div>
          </div>
        </div>
        <!-- Barra indicadora de los datos -->
        <div class="timerContainer">
          <div class="row justify-content-center">
            <div class="col-2">
              <div class="day-container">
                <button
                  @click="switchDay('Sunday', status)"
                  id="sun"
                  type="button"
                  class="daysButton delete-active"
                  :disabled="!usuarioFarmacia.admin"
                >
                  {{ $t("profile.sunday") }}
                  <!-- {{ languaje == "es" ? "DOMINGO" : "SUNDAY" }} -->
                </button>
              </div>
              <div class="day-container">
                <button
                  @click="switchDay('Monday', status)"
                  id="mon"
                  type="button"
                  class="daysButton"
                  :disabled="!usuarioFarmacia.admin"
                >
                  {{ $t("profile.monday") }}
                  <!-- {{ languaje == "es" ? "LUNES" : "MONDAY" }} -->
                </button>
              </div>
              <div class="day-container">
                <button
                  @click="switchDay('Tuesday', status)"
                  id="tue"
                  type="button"
                  class="daysButton"
                  :disabled="!usuarioFarmacia.admin"
                >
                  {{ $t("profile.tuesday") }}
                  <!-- {{ languaje == "es" ? "MARTES" : "TUESDAY" }} -->
                </button>
              </div>
              <div class="day-container">
                <button
                  @click="switchDay('Wednesday', status)"
                  id="wed"
                  type="button"
                  class="daysButton"
                  :disabled="!usuarioFarmacia.admin"
                >
                  {{ $t("profile.wednesday") }}
                  <!-- {{ languaje == "es" ? "MIERCOLES" : "WEDNESDAY" }} -->
                </button>
              </div>
              <div class="day-container">
                <button
                  @click="switchDay('Thursday', status)"
                  id="thu"
                  type="button"
                  class="daysButton"
                  :disabled="!usuarioFarmacia.admin"
                >
                  {{ $t("profile.thursday") }}
                  <!-- {{ languaje == "es" ? "JUEVES" : "THURSDAY" }} -->
                </button>
              </div>
              <div class="day-container">
                <button
                  @click="switchDay('Friday', status)"
                  id="fri"
                  type="button"
                  class="daysButton"
                  :disabled="!usuarioFarmacia.admin"
                >
                  {{ $t("profile.friday") }}
                  <!-- {{ languaje == "es" ? "VIERNES" : "FRIDAY" }} -->
                </button>
              </div>
              <div class="day-container">
                <button
                  @click="switchDay('Saturday', status)"
                  id="sat"
                  type="button"
                  class="daysButton"
                  :disabled="!usuarioFarmacia.admin"
                >
                  {{ $t("profile.saturday") }}
                  <!-- {{ languaje == "es" ? "SABADO" : "SATURDAY" }} -->
                </button>
              </div>
            </div>
            <div class="col-5">
              <!-- Sunday Input -->
              <select
                v-model="SundayAM"
                id="sunAM"
                class="timeSelector"
                :disabled="!usuarioFarmacia.admin"
                placeholder="0:00AM"
              >
                <!-- <option selected>0:00AM</option> -->
                <option value="12:00AM" class="title-category">12:00AM</option>
                <option value="12:30AM" class="title-category">12:30AM</option>
                <option value="1:00AM" class="title-category">1:00AM</option>
                <option value="1:30AM" class="title-category">1:30AM</option>
                <option value="2:00AM" class="title-category">2:00AM</option>
                <option value="2:30AM" class="title-category">2:30AM</option>
                <option value="3:00AM" class="title-category">3:00AM</option>
                <option value="3:30AM" class="title-category">3:30AM</option>
                <option value="4:00AM" class="title-category">4:00AM</option>
                <option value="4:30AM" class="title-category">4:30AM</option>
                <option value="5:00AM" class="title-category">5:00AM</option>
                <option value="5:30AM" class="title-category">5:30AM</option>
                <option value="6:00AM" class="title-category">6:00AM</option>
                <option value="6:30AM" class="title-category">6:30AM</option>
                <option value="7:00AM" class="title-category">7:00AM</option>
                <option value="7:30AM" class="title-category">7:30AM</option>
                <option value="8:00AM" class="title-category">8:00AM</option>
                <option value="8:30AM" class="title-category">8:30AM</option>
                <option value="9:00AM" class="title-category">9:00AM</option>
                <option value="9:30AM" class="title-category">9:30AM</option>
                <option value="10:00AM" class="title-category">10:00AM</option>
                <option value="10:30AM" class="title-category">10:30AM</option>
                <option value="11:00AM" class="title-category">11:00AM</option>
                <option value="11:30AM" class="title-category">11:30AM</option>
                <option value="12:00PM" class="title-category">12:00PM</option>
                <option value="12:30PM" class="title-category">12:30PM</option>
                <option value="1:00PM" class="title-category">1:00PM</option>
                <option value="1:30PM" class="title-category">1:30PM</option>
                <option value="2:00PM" class="title-category">2:00PM</option>
                <option value="2:30PM" class="title-category">2:30PM</option>
                <option value="3:00PM" class="title-category">3:00PM</option>
                <option value="3:30PM" class="title-category">3:30PM</option>
                <option value="4:00PM" class="title-category">4:00PM</option>
                <option value="4:30PM" class="title-category">4:30PM</option>
                <option value="5:00PM" class="title-category">5:00PM</option>
                <option value="5:30PM" class="title-category">5:30PM</option>
                <option value="6:00PM" class="title-category">6:00PM</option>
                <option value="6:30PM" class="title-category">6:30PM</option>
                <option value="7:00PM" class="title-category">7:00PM</option>
                <option value="7:30PM" class="title-category">7:30PM</option>
                <option value="8:00PM" class="title-category">8:00PM</option>
                <option value="8:30PM" class="title-category">8:30PM</option>
                <option value="9:00PM" class="title-category">9:00PM</option>
                <option value="9:30PM" class="title-category">9:30PM</option>
                <option value="10:00PM" class="title-category">10:00PM</option>
                <option value="10:30PM" class="title-category">10:30PM</option>
                <option value="11:00PM" class="title-category">11:00PM</option>
                <option value="11:30PM" class="title-category">11:30PM</option>
              </select>
              <select
                v-model="MondayAM"
                id="monAM"
                class="timeSelector"
                :disabled="!usuarioFarmacia.admin"
                placeholder="0:00AM"
              >
                <option value="12:00AM" class="title-category">12:00AM</option>
                <option value="12:30AM" class="title-category">12:30AM</option>
                <option value="1:00AM" class="title-category">1:00AM</option>
                <option value="1:30AM" class="title-category">1:30AM</option>
                <option value="2:00AM" class="title-category">2:00AM</option>
                <option value="2:30AM" class="title-category">2:30AM</option>
                <option value="3:00AM" class="title-category">3:00AM</option>
                <option value="3:30AM" class="title-category">3:30AM</option>
                <option value="4:00AM" class="title-category">4:00AM</option>
                <option value="4:30AM" class="title-category">4:30AM</option>
                <option value="5:00AM" class="title-category">5:00AM</option>
                <option value="5:30AM" class="title-category">5:30AM</option>
                <option value="6:00AM" class="title-category">6:00AM</option>
                <option value="6:30AM" class="title-category">6:30AM</option>
                <option value="7:00AM" class="title-category">7:00AM</option>
                <option value="7:30AM" class="title-category">7:30AM</option>
                <option value="8:00AM" class="title-category">8:00AM</option>
                <option value="8:30AM" class="title-category">8:30AM</option>
                <option value="9:00AM" class="title-category">9:00AM</option>
                <option value="9:30AM" class="title-category">9:30AM</option>
                <option value="10:00AM" class="title-category">10:00AM</option>
                <option value="10:30AM" class="title-category">10:30AM</option>
                <option value="11:00AM" class="title-category">11:00AM</option>
                <option value="11:30AM" class="title-category">11:30AM</option>
                <option value="12:00PM" class="title-category">12:00PM</option>
                <option value="12:30PM" class="title-category">12:30PM</option>
                <option value="1:00PM" class="title-category">1:00PM</option>
                <option value="1:30PM" class="title-category">1:30PM</option>
                <option value="2:00PM" class="title-category">2:00PM</option>
                <option value="2:30PM" class="title-category">2:30PM</option>
                <option value="3:00PM" class="title-category">3:00PM</option>
                <option value="3:30PM" class="title-category">3:30PM</option>
                <option value="4:00PM" class="title-category">4:00PM</option>
                <option value="4:30PM" class="title-category">4:30PM</option>
                <option value="5:00PM" class="title-category">5:00PM</option>
                <option value="5:30PM" class="title-category">5:30PM</option>
                <option value="6:00PM" class="title-category">6:00PM</option>
                <option value="6:30PM" class="title-category">6:30PM</option>
                <option value="7:00PM" class="title-category">7:00PM</option>
                <option value="7:30PM" class="title-category">7:30PM</option>
                <option value="8:00PM" class="title-category">8:00PM</option>
                <option value="8:30PM" class="title-category">8:30PM</option>
                <option value="9:00PM" class="title-category">9:00PM</option>
                <option value="9:30PM" class="title-category">9:30PM</option>
                <option value="10:00PM" class="title-category">10:00PM</option>
                <option value="10:30PM" class="title-category">10:30PM</option>
                <option value="11:00PM" class="title-category">11:00PM</option>
                <option value="11:30PM" class="title-category">11:30PM</option>
              </select>
              <!--Tuesday Input -->
              <select
                v-model="TuesdayAM"
                id="tueAM"
                class="timeSelector"
                :disabled="!usuarioFarmacia.admin"
                placeholder="0:00AM"
              >
                <option value="12:00AM" class="title-category">12:00AM</option>
                <option value="12:30AM" class="title-category">12:30AM</option>
                <option value="1:00AM" class="title-category">1:00AM</option>
                <option value="1:30AM" class="title-category">1:30AM</option>
                <option value="2:00AM" class="title-category">2:00AM</option>
                <option value="2:30AM" class="title-category">2:30AM</option>
                <option value="3:00AM" class="title-category">3:00AM</option>
                <option value="3:30AM" class="title-category">3:30AM</option>
                <option value="4:00AM" class="title-category">4:00AM</option>
                <option value="4:30AM" class="title-category">4:30AM</option>
                <option value="5:00AM" class="title-category">5:00AM</option>
                <option value="5:30AM" class="title-category">5:30AM</option>
                <option value="6:00AM" class="title-category">6:00AM</option>
                <option value="6:30AM" class="title-category">6:30AM</option>
                <option value="7:00AM" class="title-category">7:00AM</option>
                <option value="7:30AM" class="title-category">7:30AM</option>
                <option value="8:00AM" class="title-category">8:00AM</option>
                <option value="8:30AM" class="title-category">8:30AM</option>
                <option value="9:00AM" class="title-category">9:00AM</option>
                <option value="9:30AM" class="title-category">9:30AM</option>
                <option value="10:00AM" class="title-category">10:00AM</option>
                <option value="10:30AM" class="title-category">10:30AM</option>
                <option value="11:00AM" class="title-category">11:00AM</option>
                <option value="11:30AM" class="title-category">11:30AM</option>
                <option value="12:00PM" class="title-category">12:00PM</option>
                <option value="12:30PM" class="title-category">12:30PM</option>
                <option value="1:00PM" class="title-category">1:00PM</option>
                <option value="1:30PM" class="title-category">1:30PM</option>
                <option value="2:00PM" class="title-category">2:00PM</option>
                <option value="2:30PM" class="title-category">2:30PM</option>
                <option value="3:00PM" class="title-category">3:00PM</option>
                <option value="3:30PM" class="title-category">3:30PM</option>
                <option value="4:00PM" class="title-category">4:00PM</option>
                <option value="4:30PM" class="title-category">4:30PM</option>
                <option value="5:00PM" class="title-category">5:00PM</option>
                <option value="5:30PM" class="title-category">5:30PM</option>
                <option value="6:00PM" class="title-category">6:00PM</option>
                <option value="6:30PM" class="title-category">6:30PM</option>
                <option value="7:00PM" class="title-category">7:00PM</option>
                <option value="7:30PM" class="title-category">7:30PM</option>
                <option value="8:00PM" class="title-category">8:00PM</option>
                <option value="8:30PM" class="title-category">8:30PM</option>
                <option value="9:00PM" class="title-category">9:00PM</option>
                <option value="9:30PM" class="title-category">9:30PM</option>
                <option value="10:00PM" class="title-category">10:00PM</option>
                <option value="10:30PM" class="title-category">10:30PM</option>
                <option value="11:00PM" class="title-category">11:00PM</option>
                <option value="11:30PM" class="title-category">11:30PM</option>
              </select>
              <!-- Wednesday Input -->
              <select
                v-model="WednesdayAM"
                id="wedAM"
                class="timeSelector"
                :disabled="!usuarioFarmacia.admin"
                placeholder="0:00AM"
              >
                <option value="12:00AM" class="title-category">12:00AM</option>
                <option value="12:30AM" class="title-category">12:30AM</option>
                <option value="1:00AM" class="title-category">1:00AM</option>
                <option value="1:30AM" class="title-category">1:30AM</option>
                <option value="2:00AM" class="title-category">2:00AM</option>
                <option value="2:30AM" class="title-category">2:30AM</option>
                <option value="3:00AM" class="title-category">3:00AM</option>
                <option value="3:30AM" class="title-category">3:30AM</option>
                <option value="4:00AM" class="title-category">4:00AM</option>
                <option value="4:30AM" class="title-category">4:30AM</option>
                <option value="5:00AM" class="title-category">5:00AM</option>
                <option value="5:30AM" class="title-category">5:30AM</option>
                <option value="6:00AM" class="title-category">6:00AM</option>
                <option value="6:30AM" class="title-category">6:30AM</option>
                <option value="7:00AM" class="title-category">7:00AM</option>
                <option value="7:30AM" class="title-category">7:30AM</option>
                <option value="8:00AM" class="title-category">8:00AM</option>
                <option value="8:30AM" class="title-category">8:30AM</option>
                <option value="9:00AM" class="title-category">9:00AM</option>
                <option value="9:30AM" class="title-category">9:30AM</option>
                <option value="10:00AM" class="title-category">10:00AM</option>
                <option value="10:30AM" class="title-category">10:30AM</option>
                <option value="11:00AM" class="title-category">11:00AM</option>
                <option value="11:30AM" class="title-category">11:30AM</option>
                <option value="12:00PM" class="title-category">12:00PM</option>
                <option value="12:30PM" class="title-category">12:30PM</option>
                <option value="1:00PM" class="title-category">1:00PM</option>
                <option value="1:30PM" class="title-category">1:30PM</option>
                <option value="2:00PM" class="title-category">2:00PM</option>
                <option value="2:30PM" class="title-category">2:30PM</option>
                <option value="3:00PM" class="title-category">3:00PM</option>
                <option value="3:30PM" class="title-category">3:30PM</option>
                <option value="4:00PM" class="title-category">4:00PM</option>
                <option value="4:30PM" class="title-category">4:30PM</option>
                <option value="5:00PM" class="title-category">5:00PM</option>
                <option value="5:30PM" class="title-category">5:30PM</option>
                <option value="6:00PM" class="title-category">6:00PM</option>
                <option value="6:30PM" class="title-category">6:30PM</option>
                <option value="7:00PM" class="title-category">7:00PM</option>
                <option value="7:30PM" class="title-category">7:30PM</option>
                <option value="8:00PM" class="title-category">8:00PM</option>
                <option value="8:30PM" class="title-category">8:30PM</option>
                <option value="9:00PM" class="title-category">9:00PM</option>
                <option value="9:30PM" class="title-category">9:30PM</option>
                <option value="10:00PM" class="title-category">10:00PM</option>
                <option value="10:30PM" class="title-category">10:30PM</option>
                <option value="11:00PM" class="title-category">11:00PM</option>
                <option value="11:30PM" class="title-category">11:30PM</option>
              </select>
              <!-- Thursday Input -->
              <select
                v-model="ThursdayAM"
                id="thuAM"
                class="timeSelector"
                :disabled="!usuarioFarmacia.admin"
                cplaceholder="0:00AM"
              >
                <option value="12:00AM" class="title-category">12:00AM</option>
                <option value="12:30AM" class="title-category">12:30AM</option>
                <option value="1:00AM" class="title-category">1:00AM</option>
                <option value="1:30AM" class="title-category">1:30AM</option>
                <option value="2:00AM" class="title-category">2:00AM</option>
                <option value="2:30AM" class="title-category">2:30AM</option>
                <option value="3:00AM" class="title-category">3:00AM</option>
                <option value="3:30AM" class="title-category">3:30AM</option>
                <option value="4:00AM" class="title-category">4:00AM</option>
                <option value="4:30AM" class="title-category">4:30AM</option>
                <option value="5:00AM" class="title-category">5:00AM</option>
                <option value="5:30AM" class="title-category">5:30AM</option>
                <option value="6:00AM" class="title-category">6:00AM</option>
                <option value="6:30AM" class="title-category">6:30AM</option>
                <option value="7:00AM" class="title-category">7:00AM</option>
                <option value="7:30AM" class="title-category">7:30AM</option>
                <option value="8:00AM" class="title-category">8:00AM</option>
                <option value="8:30AM" class="title-category">8:30AM</option>
                <option value="9:00AM" class="title-category">9:00AM</option>
                <option value="9:30AM" class="title-category">9:30AM</option>
                <option value="10:00AM" class="title-category">10:00AM</option>
                <option value="10:30AM" class="title-category">10:30AM</option>
                <option value="11:00AM" class="title-category">11:00AM</option>
                <option value="11:30AM" class="title-category">11:30AM</option>
                <option value="12:00PM" class="title-category">12:00PM</option>
                <option value="12:30PM" class="title-category">12:30PM</option>
                <option value="1:00PM" class="title-category">1:00PM</option>
                <option value="1:30PM" class="title-category">1:30PM</option>
                <option value="2:00PM" class="title-category">2:00PM</option>
                <option value="2:30PM" class="title-category">2:30PM</option>
                <option value="3:00PM" class="title-category">3:00PM</option>
                <option value="3:30PM" class="title-category">3:30PM</option>
                <option value="4:00PM" class="title-category">4:00PM</option>
                <option value="4:30PM" class="title-category">4:30PM</option>
                <option value="5:00PM" class="title-category">5:00PM</option>
                <option value="5:30PM" class="title-category">5:30PM</option>
                <option value="6:00PM" class="title-category">6:00PM</option>
                <option value="6:30PM" class="title-category">6:30PM</option>
                <option value="7:00PM" class="title-category">7:00PM</option>
                <option value="7:30PM" class="title-category">7:30PM</option>
                <option value="8:00PM" class="title-category">8:00PM</option>
                <option value="8:30PM" class="title-category">8:30PM</option>
                <option value="9:00PM" class="title-category">9:00PM</option>
                <option value="9:30PM" class="title-category">9:30PM</option>
                <option value="10:00PM" class="title-category">10:00PM</option>
                <option value="10:30PM" class="title-category">10:30PM</option>
                <option value="11:00PM" class="title-category">11:00PM</option>
                <option value="11:30PM" class="title-category">11:30PM</option>
              </select>
              <!-- Friday Input -->
              <select
                v-model="FridayAM"
                id="friAM"
                class="timeSelector"
                :disabled="!usuarioFarmacia.admin"
                placeholder="0:00AM"
              >
                <option value="12:00AM" class="title-category">12:00AM</option>
                <option value="12:30AM" class="title-category">12:30AM</option>
                <option value="1:00AM" class="title-category">1:00AM</option>
                <option value="1:30AM" class="title-category">1:30AM</option>
                <option value="2:00AM" class="title-category">2:00AM</option>
                <option value="2:30AM" class="title-category">2:30AM</option>
                <option value="3:00AM" class="title-category">3:00AM</option>
                <option value="3:30AM" class="title-category">3:30AM</option>
                <option value="4:00AM" class="title-category">4:00AM</option>
                <option value="4:30AM" class="title-category">4:30AM</option>
                <option value="5:00AM" class="title-category">5:00AM</option>
                <option value="5:30AM" class="title-category">5:30AM</option>
                <option value="6:00AM" class="title-category">6:00AM</option>
                <option value="6:30AM" class="title-category">6:30AM</option>
                <option value="7:00AM" class="title-category">7:00AM</option>
                <option value="7:30AM" class="title-category">7:30AM</option>
                <option value="8:00AM" class="title-category">8:00AM</option>
                <option value="8:30AM" class="title-category">8:30AM</option>
                <option value="9:00AM" class="title-category">9:00AM</option>
                <option value="9:30AM" class="title-category">9:30AM</option>
                <option value="10:00AM" class="title-category">10:00AM</option>
                <option value="10:30AM" class="title-category">10:30AM</option>
                <option value="11:00AM" class="title-category">11:00AM</option>
                <option value="11:30AM" class="title-category">11:30AM</option>
                <option value="12:00PM" class="title-category">12:00PM</option>
                <option value="12:30PM" class="title-category">12:30PM</option>
                <option value="1:00PM" class="title-category">1:00PM</option>
                <option value="1:30PM" class="title-category">1:30PM</option>
                <option value="2:00PM" class="title-category">2:00PM</option>
                <option value="2:30PM" class="title-category">2:30PM</option>
                <option value="3:00PM" class="title-category">3:00PM</option>
                <option value="3:30PM" class="title-category">3:30PM</option>
                <option value="4:00PM" class="title-category">4:00PM</option>
                <option value="4:30PM" class="title-category">4:30PM</option>
                <option value="5:00PM" class="title-category">5:00PM</option>
                <option value="5:30PM" class="title-category">5:30PM</option>
                <option value="6:00PM" class="title-category">6:00PM</option>
                <option value="6:30PM" class="title-category">6:30PM</option>
                <option value="7:00PM" class="title-category">7:00PM</option>
                <option value="7:30PM" class="title-category">7:30PM</option>
                <option value="8:00PM" class="title-category">8:00PM</option>
                <option value="8:30PM" class="title-category">8:30PM</option>
                <option value="9:00PM" class="title-category">9:00PM</option>
                <option value="9:30PM" class="title-category">9:30PM</option>
                <option value="10:00PM" class="title-category">10:00PM</option>
                <option value="10:30PM" class="title-category">10:30PM</option>
                <option value="11:00PM" class="title-category">11:00PM</option>
                <option value="11:30PM" class="title-category">11:30PM</option>
              </select>
              <!-- Saturday Input -->
              <select
                v-model="SaturdayAM"
                id="satAM"
                class="timeSelector"
                :disabled="!usuarioFarmacia.admin"
                placeholder="0:00AM"
              >
                <option value="12:00AM" class="title-category">12:00AM</option>
                <option value="12:30AM" class="title-category">12:30AM</option>
                <option value="1:00AM" class="title-category">1:00AM</option>
                <option value="1:30AM" class="title-category">1:30AM</option>
                <option value="2:00AM" class="title-category">2:00AM</option>
                <option value="2:30AM" class="title-category">2:30AM</option>
                <option value="3:00AM" class="title-category">3:00AM</option>
                <option value="3:30AM" class="title-category">3:30AM</option>
                <option value="4:00AM" class="title-category">4:00AM</option>
                <option value="4:30AM" class="title-category">4:30AM</option>
                <option value="5:00AM" class="title-category">5:00AM</option>
                <option value="5:30AM" class="title-category">5:30AM</option>
                <option value="6:00AM" class="title-category">6:00AM</option>
                <option value="6:30AM" class="title-category">6:30AM</option>
                <option value="7:00AM" class="title-category">7:00AM</option>
                <option value="7:30AM" class="title-category">7:30AM</option>
                <option value="8:00AM" class="title-category">8:00AM</option>
                <option value="8:30AM" class="title-category">8:30AM</option>
                <option value="9:00AM" class="title-category">9:00AM</option>
                <option value="9:30AM" class="title-category">9:30AM</option>
                <option value="10:00AM" class="title-category">10:00AM</option>
                <option value="10:30AM" class="title-category">10:30AM</option>
                <option value="11:00AM" class="title-category">11:00AM</option>
                <option value="11:30AM" class="title-category">11:30AM</option>
                <option value="12:00PM" class="title-category">12:00PM</option>
                <option value="12:30PM" class="title-category">12:30PM</option>
                <option value="1:00PM" class="title-category">1:00PM</option>
                <option value="1:30PM" class="title-category">1:30PM</option>
                <option value="2:00PM" class="title-category">2:00PM</option>
                <option value="2:30PM" class="title-category">2:30PM</option>
                <option value="3:00PM" class="title-category">3:00PM</option>
                <option value="3:30PM" class="title-category">3:30PM</option>
                <option value="4:00PM" class="title-category">4:00PM</option>
                <option value="4:30PM" class="title-category">4:30PM</option>
                <option value="5:00PM" class="title-category">5:00PM</option>
                <option value="5:30PM" class="title-category">5:30PM</option>
                <option value="6:00PM" class="title-category">6:00PM</option>
                <option value="6:30PM" class="title-category">6:30PM</option>
                <option value="7:00PM" class="title-category">7:00PM</option>
                <option value="7:30PM" class="title-category">7:30PM</option>
                <option value="8:00PM" class="title-category">8:00PM</option>
                <option value="8:30PM" class="title-category">8:30PM</option>
                <option value="9:00PM" class="title-category">9:00PM</option>
                <option value="9:30PM" class="title-category">9:30PM</option>
                <option value="10:00PM" class="title-category">10:00PM</option>
                <option value="10:30PM" class="title-category">10:30PM</option>
                <option value="11:00PM" class="title-category">11:00PM</option>
                <option value="11:30PM" class="title-category">11:30PM</option>
              </select>
            </div>
            <div class="col-5">
              <!-- Sunday Input -->
              <select
                v-model="SundayPM"
                id="sunPM"
                class="timeSelector"
                :disabled="!usuarioFarmacia.admin"
                placeholder="0:00PM"
              >
                <option value="12:00AM" class="title-category">12:00AM</option>
                <option value="12:30AM" class="title-category">12:30AM</option>
                <option value="1:00AM" class="title-category">1:00AM</option>
                <option value="1:30AM" class="title-category">1:30AM</option>
                <option value="2:00AM" class="title-category">2:00AM</option>
                <option value="2:30AM" class="title-category">2:30AM</option>
                <option value="3:00AM" class="title-category">3:00AM</option>
                <option value="3:30AM" class="title-category">3:30AM</option>
                <option value="4:00AM" class="title-category">4:00AM</option>
                <option value="4:30AM" class="title-category">4:30AM</option>
                <option value="5:00AM" class="title-category">5:00AM</option>
                <option value="5:30AM" class="title-category">5:30AM</option>
                <option value="6:00AM" class="title-category">6:00AM</option>
                <option value="6:30AM" class="title-category">6:30AM</option>
                <option value="7:00AM" class="title-category">7:00AM</option>
                <option value="7:30AM" class="title-category">7:30AM</option>
                <option value="8:00AM" class="title-category">8:00AM</option>
                <option value="8:30AM" class="title-category">8:30AM</option>
                <option value="9:00AM" class="title-category">9:00AM</option>
                <option value="9:30AM" class="title-category">9:30AM</option>
                <option value="10:00AM" class="title-category">10:00AM</option>
                <option value="10:30AM" class="title-category">10:30AM</option>
                <option value="11:00AM" class="title-category">11:00AM</option>
                <option value="11:30AM" class="title-category">11:30AM</option>
                <option value="12:00PM" class="title-category">12:00PM</option>
                <option value="12:30PM" class="title-category">12:30PM</option>
                <option value="1:00PM" class="title-category">1:00PM</option>
                <option value="1:30PM" class="title-category">1:30PM</option>
                <option value="2:00PM" class="title-category">2:00PM</option>
                <option value="2:30PM" class="title-category">2:30PM</option>
                <option value="3:00PM" class="title-category">3:00PM</option>
                <option value="3:30PM" class="title-category">3:30PM</option>
                <option value="4:00PM" class="title-category">4:00PM</option>
                <option value="4:30PM" class="title-category">4:30PM</option>
                <option value="5:00PM" class="title-category">5:00PM</option>
                <option value="5:30PM" class="title-category">5:30PM</option>
                <option value="6:00PM" class="title-category">6:00PM</option>
                <option value="6:30PM" class="title-category">6:30PM</option>
                <option value="7:00PM" class="title-category">7:00PM</option>
                <option value="7:30PM" class="title-category">7:30PM</option>
                <option value="8:00PM" class="title-category">8:00PM</option>
                <option value="8:30PM" class="title-category">8:30PM</option>
                <option value="9:00PM" class="title-category">9:00PM</option>
                <option value="9:30PM" class="title-category">9:30PM</option>
                <option value="10:00PM" class="title-category">10:00PM</option>
                <option value="10:30PM" class="title-category">10:30PM</option>
                <option value="11:00PM" class="title-category">11:00PM</option>
                <option value="11:30PM" class="title-category">11:30PM</option>
                <option value="11:59PM" class="title-category">11:59PM</option>
              </select>
              <!-- Monday Input -->
              <select
                v-model="MondayPM"
                id="monPM"
                class="timeSelector"
                :disabled="!usuarioFarmacia.admin"
                placeholder="0:00PM"
              >
                <option value="12:00AM" class="title-category">12:00AM</option>
                <option value="12:30AM" class="title-category">12:30AM</option>
                <option value="1:00AM" class="title-category">1:00AM</option>
                <option value="1:30AM" class="title-category">1:30AM</option>
                <option value="2:00AM" class="title-category">2:00AM</option>
                <option value="2:30AM" class="title-category">2:30AM</option>
                <option value="3:00AM" class="title-category">3:00AM</option>
                <option value="3:30AM" class="title-category">3:30AM</option>
                <option value="4:00AM" class="title-category">4:00AM</option>
                <option value="4:30AM" class="title-category">4:30AM</option>
                <option value="5:00AM" class="title-category">5:00AM</option>
                <option value="5:30AM" class="title-category">5:30AM</option>
                <option value="6:00AM" class="title-category">6:00AM</option>
                <option value="6:30AM" class="title-category">6:30AM</option>
                <option value="7:00AM" class="title-category">7:00AM</option>
                <option value="7:30AM" class="title-category">7:30AM</option>
                <option value="8:00AM" class="title-category">8:00AM</option>
                <option value="8:30AM" class="title-category">8:30AM</option>
                <option value="9:00AM" class="title-category">9:00AM</option>
                <option value="9:30AM" class="title-category">9:30AM</option>
                <option value="10:00AM" class="title-category">10:00AM</option>
                <option value="10:30AM" class="title-category">10:30AM</option>
                <option value="11:00AM" class="title-category">11:00AM</option>
                <option value="11:30AM" class="title-category">11:30AM</option>
                <option value="12:00PM" class="title-category">12:00PM</option>
                <option value="12:30PM" class="title-category">12:30PM</option>
                <option value="1:00PM" class="title-category">1:00PM</option>
                <option value="1:30PM" class="title-category">1:30PM</option>
                <option value="2:00PM" class="title-category">2:00PM</option>
                <option value="2:30PM" class="title-category">2:30PM</option>
                <option value="3:00PM" class="title-category">3:00PM</option>
                <option value="3:30PM" class="title-category">3:30PM</option>
                <option value="4:00PM" class="title-category">4:00PM</option>
                <option value="4:30PM" class="title-category">4:30PM</option>
                <option value="5:00PM" class="title-category">5:00PM</option>
                <option value="5:30PM" class="title-category">5:30PM</option>
                <option value="6:00PM" class="title-category">6:00PM</option>
                <option value="6:30PM" class="title-category">6:30PM</option>
                <option value="7:00PM" class="title-category">7:00PM</option>
                <option value="7:30PM" class="title-category">7:30PM</option>
                <option value="8:00PM" class="title-category">8:00PM</option>
                <option value="8:30PM" class="title-category">8:30PM</option>
                <option value="9:00PM" class="title-category">9:00PM</option>
                <option value="9:30PM" class="title-category">9:30PM</option>
                <option value="10:00PM" class="title-category">10:00PM</option>
                <option value="10:30PM" class="title-category">10:30PM</option>
                <option value="11:00PM" class="title-category">11:00PM</option>
                <option value="11:30PM" class="title-category">11:30PM</option>
                <option value="11:59PM" class="title-category">11:59PM</option>
              </select>
              <!--Tuesday Input -->
              <select
                v-model="TuesdayPM"
                id="tuePM"
                class="timeSelector"
                :disabled="!usuarioFarmacia.admin"
                placeholder="0:00PM"
              >
                <option value="12:00AM" class="title-category">12:00AM</option>
                <option value="12:30AM" class="title-category">12:30AM</option>
                <option value="1:00AM" class="title-category">1:00AM</option>
                <option value="1:30AM" class="title-category">1:30AM</option>
                <option value="2:00AM" class="title-category">2:00AM</option>
                <option value="2:30AM" class="title-category">2:30AM</option>
                <option value="3:00AM" class="title-category">3:00AM</option>
                <option value="3:30AM" class="title-category">3:30AM</option>
                <option value="4:00AM" class="title-category">4:00AM</option>
                <option value="4:30AM" class="title-category">4:30AM</option>
                <option value="5:00AM" class="title-category">5:00AM</option>
                <option value="5:30AM" class="title-category">5:30AM</option>
                <option value="6:00AM" class="title-category">6:00AM</option>
                <option value="6:30AM" class="title-category">6:30AM</option>
                <option value="7:00AM" class="title-category">7:00AM</option>
                <option value="7:30AM" class="title-category">7:30AM</option>
                <option value="8:00AM" class="title-category">8:00AM</option>
                <option value="8:30AM" class="title-category">8:30AM</option>
                <option value="9:00AM" class="title-category">9:00AM</option>
                <option value="9:30AM" class="title-category">9:30AM</option>
                <option value="10:00AM" class="title-category">10:00AM</option>
                <option value="10:30AM" class="title-category">10:30AM</option>
                <option value="11:00AM" class="title-category">11:00AM</option>
                <option value="11:30AM" class="title-category">11:30AM</option>
                <option value="12:00PM" class="title-category">12:00PM</option>
                <option value="12:30PM" class="title-category">12:30PM</option>
                <option value="1:00PM" class="title-category">1:00PM</option>
                <option value="1:30PM" class="title-category">1:30PM</option>
                <option value="2:00PM" class="title-category">2:00PM</option>
                <option value="2:30PM" class="title-category">2:30PM</option>
                <option value="3:00PM" class="title-category">3:00PM</option>
                <option value="3:30PM" class="title-category">3:30PM</option>
                <option value="4:00PM" class="title-category">4:00PM</option>
                <option value="4:30PM" class="title-category">4:30PM</option>
                <option value="5:00PM" class="title-category">5:00PM</option>
                <option value="5:30PM" class="title-category">5:30PM</option>
                <option value="6:00PM" class="title-category">6:00PM</option>
                <option value="6:30PM" class="title-category">6:30PM</option>
                <option value="7:00PM" class="title-category">7:00PM</option>
                <option value="7:30PM" class="title-category">7:30PM</option>
                <option value="8:00PM" class="title-category">8:00PM</option>
                <option value="8:30PM" class="title-category">8:30PM</option>
                <option value="9:00PM" class="title-category">9:00PM</option>
                <option value="9:30PM" class="title-category">9:30PM</option>
                <option value="10:00PM" class="title-category">10:00PM</option>
                <option value="10:30PM" class="title-category">10:30PM</option>
                <option value="11:00PM" class="title-category">11:00PM</option>
                <option value="11:30PM" class="title-category">11:30PM</option>
                <option value="11:59PM" class="title-category">11:59PM</option>
              </select>
              <!-- Wednesday Input -->
              <select
                v-model="WednesdayPM"
                id="wedPM"
                class="timeSelector"
                :disabled="!usuarioFarmacia.admin"
                placeholder="0:00PM"
              >
                <option value="12:00AM" class="title-category">12:00AM</option>
                <option value="12:30AM" class="title-category">12:30AM</option>
                <option value="1:00AM" class="title-category">1:00AM</option>
                <option value="1:30AM" class="title-category">1:30AM</option>
                <option value="2:00AM" class="title-category">2:00AM</option>
                <option value="2:30AM" class="title-category">2:30AM</option>
                <option value="3:00AM" class="title-category">3:00AM</option>
                <option value="3:30AM" class="title-category">3:30AM</option>
                <option value="4:00AM" class="title-category">4:00AM</option>
                <option value="4:30AM" class="title-category">4:30AM</option>
                <option value="5:00AM" class="title-category">5:00AM</option>
                <option value="5:30AM" class="title-category">5:30AM</option>
                <option value="6:00AM" class="title-category">6:00AM</option>
                <option value="6:30AM" class="title-category">6:30AM</option>
                <option value="7:00AM" class="title-category">7:00AM</option>
                <option value="7:30AM" class="title-category">7:30AM</option>
                <option value="8:00AM" class="title-category">8:00AM</option>
                <option value="8:30AM" class="title-category">8:30AM</option>
                <option value="9:00AM" class="title-category">9:00AM</option>
                <option value="9:30AM" class="title-category">9:30AM</option>
                <option value="10:00AM" class="title-category">10:00AM</option>
                <option value="10:30AM" class="title-category">10:30AM</option>
                <option value="11:00AM" class="title-category">11:00AM</option>
                <option value="11:30AM" class="title-category">11:30AM</option>
                <option value="12:00PM" class="title-category">12:00PM</option>
                <option value="12:30PM" class="title-category">12:30PM</option>
                <option value="1:00PM" class="title-category">1:00PM</option>
                <option value="1:30PM" class="title-category">1:30PM</option>
                <option value="2:00PM" class="title-category">2:00PM</option>
                <option value="2:30PM" class="title-category">2:30PM</option>
                <option value="3:00PM" class="title-category">3:00PM</option>
                <option value="3:30PM" class="title-category">3:30PM</option>
                <option value="4:00PM" class="title-category">4:00PM</option>
                <option value="4:30PM" class="title-category">4:30PM</option>
                <option value="5:00PM" class="title-category">5:00PM</option>
                <option value="5:30PM" class="title-category">5:30PM</option>
                <option value="6:00PM" class="title-category">6:00PM</option>
                <option value="6:30PM" class="title-category">6:30PM</option>
                <option value="7:00PM" class="title-category">7:00PM</option>
                <option value="7:30PM" class="title-category">7:30PM</option>
                <option value="8:00PM" class="title-category">8:00PM</option>
                <option value="8:30PM" class="title-category">8:30PM</option>
                <option value="9:00PM" class="title-category">9:00PM</option>
                <option value="9:30PM" class="title-category">9:30PM</option>
                <option value="10:00PM" class="title-category">10:00PM</option>
                <option value="10:30PM" class="title-category">10:30PM</option>
                <option value="11:00PM" class="title-category">11:00PM</option>
                <option value="11:30PM" class="title-category">11:30PM</option>
                <option value="11:59PM" class="title-category">11:59PM</option>
              </select>
              <!-- Thursday Input -->
              <select
                v-model="ThursdayPM"
                id="thuPM"
                class="timeSelector"
                :disabled="!usuarioFarmacia.admin"
                placeholder="0:00PM"
              >
                <option value="12:00AM" class="title-category">12:00AM</option>
                <option value="12:30AM" class="title-category">12:30AM</option>
                <option value="1:00AM" class="title-category">1:00AM</option>
                <option value="1:30AM" class="title-category">1:30AM</option>
                <option value="2:00AM" class="title-category">2:00AM</option>
                <option value="2:30AM" class="title-category">2:30AM</option>
                <option value="3:00AM" class="title-category">3:00AM</option>
                <option value="3:30AM" class="title-category">3:30AM</option>
                <option value="4:00AM" class="title-category">4:00AM</option>
                <option value="4:30AM" class="title-category">4:30AM</option>
                <option value="5:00AM" class="title-category">5:00AM</option>
                <option value="5:30AM" class="title-category">5:30AM</option>
                <option value="6:00AM" class="title-category">6:00AM</option>
                <option value="6:30AM" class="title-category">6:30AM</option>
                <option value="7:00AM" class="title-category">7:00AM</option>
                <option value="7:30AM" class="title-category">7:30AM</option>
                <option value="8:00AM" class="title-category">8:00AM</option>
                <option value="8:30AM" class="title-category">8:30AM</option>
                <option value="9:00AM" class="title-category">9:00AM</option>
                <option value="9:30AM" class="title-category">9:30AM</option>
                <option value="10:00AM" class="title-category">10:00AM</option>
                <option value="10:30AM" class="title-category">10:30AM</option>
                <option value="11:00AM" class="title-category">11:00AM</option>
                <option value="11:30AM" class="title-category">11:30AM</option>
                <option value="12:00PM" class="title-category">12:00PM</option>
                <option value="12:30PM" class="title-category">12:30PM</option>
                <option value="1:00PM" class="title-category">1:00PM</option>
                <option value="1:30PM" class="title-category">1:30PM</option>
                <option value="2:00PM" class="title-category">2:00PM</option>
                <option value="2:30PM" class="title-category">2:30PM</option>
                <option value="3:00PM" class="title-category">3:00PM</option>
                <option value="3:30PM" class="title-category">3:30PM</option>
                <option value="4:00PM" class="title-category">4:00PM</option>
                <option value="4:30PM" class="title-category">4:30PM</option>
                <option value="5:00PM" class="title-category">5:00PM</option>
                <option value="5:30PM" class="title-category">5:30PM</option>
                <option value="6:00PM" class="title-category">6:00PM</option>
                <option value="6:30PM" class="title-category">6:30PM</option>
                <option value="7:00PM" class="title-category">7:00PM</option>
                <option value="7:30PM" class="title-category">7:30PM</option>
                <option value="8:00PM" class="title-category">8:00PM</option>
                <option value="8:30PM" class="title-category">8:30PM</option>
                <option value="9:00PM" class="title-category">9:00PM</option>
                <option value="9:30PM" class="title-category">9:30PM</option>
                <option value="10:00PM" class="title-category">10:00PM</option>
                <option value="10:30PM" class="title-category">10:30PM</option>
                <option value="11:00PM" class="title-category">11:00PM</option>
                <option value="11:30PM" class="title-category">11:30PM</option>
                <option value="11:59PM" class="title-category">11:59PM</option>
              </select>
              <!-- Friday Input -->
              <select
                v-model="FridayPM"
                id="friPM"
                class="timeSelector"
                :disabled="!usuarioFarmacia.admin"
                placeholder="0:00PM"
              >
                <option value="12:00AM" class="title-category">12:00AM</option>
                <option value="12:30AM" class="title-category">12:30AM</option>
                <option value="1:00AM" class="title-category">1:00AM</option>
                <option value="1:30AM" class="title-category">1:30AM</option>
                <option value="2:00AM" class="title-category">2:00AM</option>
                <option value="2:30AM" class="title-category">2:30AM</option>
                <option value="3:00AM" class="title-category">3:00AM</option>
                <option value="3:30AM" class="title-category">3:30AM</option>
                <option value="4:00AM" class="title-category">4:00AM</option>
                <option value="4:30AM" class="title-category">4:30AM</option>
                <option value="5:00AM" class="title-category">5:00AM</option>
                <option value="5:30AM" class="title-category">5:30AM</option>
                <option value="6:00AM" class="title-category">6:00AM</option>
                <option value="6:30AM" class="title-category">6:30AM</option>
                <option value="7:00AM" class="title-category">7:00AM</option>
                <option value="7:30AM" class="title-category">7:30AM</option>
                <option value="8:00AM" class="title-category">8:00AM</option>
                <option value="8:30AM" class="title-category">8:30AM</option>
                <option value="9:00AM" class="title-category">9:00AM</option>
                <option value="9:30AM" class="title-category">9:30AM</option>
                <option value="10:00AM" class="title-category">10:00AM</option>
                <option value="10:30AM" class="title-category">10:30AM</option>
                <option value="11:00AM" class="title-category">11:00AM</option>
                <option value="11:30AM" class="title-category">11:30AM</option>
                <option value="12:00PM" class="title-category">12:00PM</option>
                <option value="12:30PM" class="title-category">12:30PM</option>
                <option value="1:00PM" class="title-category">1:00PM</option>
                <option value="1:30PM" class="title-category">1:30PM</option>
                <option value="2:00PM" class="title-category">2:00PM</option>
                <option value="2:30PM" class="title-category">2:30PM</option>
                <option value="3:00PM" class="title-category">3:00PM</option>
                <option value="3:30PM" class="title-category">3:30PM</option>
                <option value="4:00PM" class="title-category">4:00PM</option>
                <option value="4:30PM" class="title-category">4:30PM</option>
                <option value="5:00PM" class="title-category">5:00PM</option>
                <option value="5:30PM" class="title-category">5:30PM</option>
                <option value="6:00PM" class="title-category">6:00PM</option>
                <option value="6:30PM" class="title-category">6:30PM</option>
                <option value="7:00PM" class="title-category">7:00PM</option>
                <option value="7:30PM" class="title-category">7:30PM</option>
                <option value="8:00PM" class="title-category">8:00PM</option>
                <option value="8:30PM" class="title-category">8:30PM</option>
                <option value="9:00PM" class="title-category">9:00PM</option>
                <option value="9:30PM" class="title-category">9:30PM</option>
                <option value="10:00PM" class="title-category">10:00PM</option>
                <option value="10:30PM" class="title-category">10:30PM</option>
                <option value="11:00PM" class="title-category">11:00PM</option>
                <option value="11:30PM" class="title-category">11:30PM</option>
                <option value="11:59PM" class="title-category">11:59PM</option>
              </select>
              <!-- Saturday Input -->
              <select
                v-model="SaturdayPM"
                id="satPM"
                class="timeSelector"
                :disabled="!usuarioFarmacia.admin"
                placeholder="0:00PM"
              >
                <option value="12:00AM" class="title-category">12:00AM</option>
                <option value="12:30AM" class="title-category">12:30AM</option>
                <option value="1:00AM" class="title-category">1:00AM</option>
                <option value="1:30AM" class="title-category">1:30AM</option>
                <option value="2:00AM" class="title-category">2:00AM</option>
                <option value="2:30AM" class="title-category">2:30AM</option>
                <option value="3:00AM" class="title-category">3:00AM</option>
                <option value="3:30AM" class="title-category">3:30AM</option>
                <option value="4:00AM" class="title-category">4:00AM</option>
                <option value="4:30AM" class="title-category">4:30AM</option>
                <option value="5:00AM" class="title-category">5:00AM</option>
                <option value="5:30AM" class="title-category">5:30AM</option>
                <option value="6:00AM" class="title-category">6:00AM</option>
                <option value="6:30AM" class="title-category">6:30AM</option>
                <option value="7:00AM" class="title-category">7:00AM</option>
                <option value="7:30AM" class="title-category">7:30AM</option>
                <option value="8:00AM" class="title-category">8:00AM</option>
                <option value="8:30AM" class="title-category">8:30AM</option>
                <option value="9:00AM" class="title-category">9:00AM</option>
                <option value="9:30AM" class="title-category">9:30AM</option>
                <option value="10:00AM" class="title-category">10:00AM</option>
                <option value="10:30AM" class="title-category">10:30AM</option>
                <option value="11:00AM" class="title-category">11:00AM</option>
                <option value="11:30AM" class="title-category">11:30AM</option>
                <option value="12:00PM" class="title-category">12:00PM</option>
                <option value="12:30PM" class="title-category">12:30PM</option>
                <option value="1:00PM" class="title-category">1:00PM</option>
                <option value="1:30PM" class="title-category">1:30PM</option>
                <option value="2:00PM" class="title-category">2:00PM</option>
                <option value="2:30PM" class="title-category">2:30PM</option>
                <option value="3:00PM" class="title-category">3:00PM</option>
                <option value="3:30PM" class="title-category">3:30PM</option>
                <option value="4:00PM" class="title-category">4:00PM</option>
                <option value="4:30PM" class="title-category">4:30PM</option>
                <option value="5:00PM" class="title-category">5:00PM</option>
                <option value="5:30PM" class="title-category">5:30PM</option>
                <option value="6:00PM" class="title-category">6:00PM</option>
                <option value="6:30PM" class="title-category">6:30PM</option>
                <option value="7:00PM" class="title-category">7:00PM</option>
                <option value="7:30PM" class="title-category">7:30PM</option>
                <option value="8:00PM" class="title-category">8:00PM</option>
                <option value="8:30PM" class="title-category">8:30PM</option>
                <option value="9:00PM" class="title-category">9:00PM</option>
                <option value="9:30PM" class="title-category">9:30PM</option>
                <option value="10:00PM" class="title-category">10:00PM</option>
                <option value="10:30PM" class="title-category">10:30PM</option>
                <option value="11:00PM" class="title-category">11:00PM</option>
                <option value="11:30PM" class="title-category">11:30PM</option>
                <option value="11:59PM" class="title-category">11:59PM</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row justify-content-center ">
          <div class="col-4 form-group" v-if="usuarioFarmacia.admin">
            <p style="text-align: center;">
              {{ $t("profile.chargePhoto") }}:
              <!-- {{
                languaje == "es"
                  ? "Cargue una foto para su tienda:"
                  : "Upload a photo for your store:"
              }} -->
            </p>
            <div style="text-align: center;" class="custom-file">
              <input
                @change="onFileSelected"
                type="file"
                class="inputPicture"
                id="photoTienda"
                accept=".png, .jpg, .jpeg"
                :disabled="!usuarioFarmacia.admin"
              />
              <label
                class="custom-file-label"
                for="photoTienda"
                style="font-family: 'Montserrat', sans-serif; font-size: 13px;"
              >
                {{ $t("profile.storePicture") }}
                <!-- {{
                  languaje == "es" ? "Foto de la Tienda" : "Store Picture"
                }} -->
              </label>
              <button
                disabled
                v-if="picture"
                class="btn btn-success btn-lg btn-block"
              >
                {{ $t("profile.uploadedPicture") }}
                <!-- {{ languaje == "es" ? "Foto Cargada" : "Uploaded Picture" }} -->
              </button>
            </div>
          </div>
          <div class="col-4" v-if="usuarioFarmacia.admin">
            <div class="form-group">
              <div style="text-align: center;" v-if="!esStripe">
                <p>
                  {{ $t("profile.connectStripe") }}:
                  <!-- {{
                    languaje == "es"
                      ? "Registrar o connectar Stripe Connect:"
                      : "Register or Connect Stripe Connect:"
                  }} -->
                </p>
                <a
                  style="font-family: Blippo, fantasy; font-weight: bold;"
                  href="https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=ca_HPcw7QIPRJuwWt4etFr3E9KUmMzX24tL&scope=read_write"
                >
                  <!-- Develop -->
                  <!-- href="https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=ca_HPcwidTG638HfuxiWR7kt3PLzEwgnWad&scope=read_write" -->

                  <!-- Produccion -->
                  <!-- href="https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=ca_HPcw7QIPRJuwWt4etFr3E9KUmMzX24tL&scope=read_write" -->
                  <img
                    class="img-responsive"
                    src="../../assets/stripe3x.png"
                    style="margin: 0px; width: 70%; max-width: 70%; height: 40px;"
                  />
                </a>
              </div>
              <div style="text-align: center;" v-else>
                <p>
                  {{ $t("profile.stripeCompleted") }}:
                  <!-- {{
                    languaje == "es"
                      ? "Stripe Connect Completado"
                      : "Stripe Connect Completed"
                  }} -->
                </p>
                <p>
                  Tu Stripe ID: <strong>{{ stripeUserId }}</strong>
                </p>
              </div>
            </div>
          </div>
          <div class="col-4" v-if="usuarioFarmacia.admin">
            <p style="text-align: center;">
              {{ $t("profile.deliveryPreference") }}:
              <!-- {{
                languaje == "es"
                  ? "Delivery de Preferencia:"
                  : "Delivery Preference"
              }} -->
            </p>
            <select class="form-control" v-model="deliverySystem">
              <option value="own">Local</option>
              <option value="coopharma">Coopharma My Shop Driver</option>
            </select>
          </div>
          <div class="col-12 mt-4 mb-4" v-if="usuarioFarmacia.admin">
            <div class="row">
              <div class="col-3"></div>
              <div class="col-6">
                <button
                  class="btn-save btn-lg btn-block"
                  @click="editInfo"
                  type="button"
                >
                  {{ $t("profile.saveChanges") }}
                  <!-- {{ languaje == "es" ? "Guardar Cambios" : "Save Changes" }} -->
                </button>
              </div>
              <div class="col-3"></div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="usuarioFarmacia.admin"
        class="row justify-content-center barStatus mt-2"
      >
        <div class="col-4">
          <button
            class="btn btn-danger btn-lg btn-block"
            type="button"
            data-toggle="modal"
            data-target="#modalUsuarios"
            @click="seeStoreUsers"
          >
            <a class="font-weight-bold" style="color:black">
              <!-- {{
              languaje == "es" ? "Administrar Usuarios" : "User Management"
            }} -->
              {{ $t("profile.managementUser") }}
            </a>
          </button>
        </div>
        <div class="col-3">
          <button
            class="btn btn-primary btn-lg btn-block"
            type="button"
            data-toggle="modal"
            data-target="#modalAddUsuarios"
            @click="openAddModal(true)"
          >
            <a class="font-weight-bold" style="color:black">
              {{ $t("profile.addUser") }}
              <!-- {{
              languaje == "es" ? "Agregar Usuario" : "Add User" -->
              <!-- }} -->
            </a>
          </button>
        </div>
      </div>
      <div v-if="backdrop" class="loader">
        <div :class="loader ? 'loader-show' : ''" class="loader-container ">
          <img src="../../assets/loader.svg" alt="" />
          <p class="loader-text">
            {{ $t("profile.loading") }}
            <!-- {{ languaje == "es" ? "Cargando..." : "Loading..." }} -->
          </p>
        </div>
      </div>

      <!-- modal usuarios -->

      <div
        class="modal fade"
        style="overflow:hidden !important"
        id="modalUsuarios"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class=" modal-position modal-dialog modal-dialog-centered"
          role="document"
        >
          <div
            style="overflow-y:auto;border-radius:0"
            class="modal-content add-product-form modalContainer"
          >
            <div class="modal-header">
              <h5
                class="modal-title text-center"
                id="exampleModalLongTitle"
                style="font-family: 'Montserrat', sans-serif;"
              >
                {{ $t("profile.adminUsers") }}
                <!-- {{
                  languaje == "es" ? "Administrar Usuarios" : "User Management"
                }} -->
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" class="h1 bold">&times;</span>
              </button>
            </div>
            <div class="modal-body specialBody mb-4">
              <div class="form-group mt-2">
                <select v-model="roleFilter" class="form-control">
                  <option
                    v-for="(rol, key) of roles"
                    :key="key"
                    :value="rol.id"
                    >{{ rol.descripcion }}</option
                  >
                </select>

                <input
                  v-if="languaje == 'es'"
                  type="text"
                  v-model="textFilter"
                  placeholder="Filtro de Usuarios"
                  class="form-control"
                  id="textFilter"
                />
                <input
                  v-else
                  type="text"
                  v-model="textFilter"
                  placeholder="User Filter"
                  class="form-control"
                  id="textFilter"
                />
              </div>
              <div class="mt-4" v-if="roleFilter == 'E'">
                <div
                  class="totalCard bg-white"
                  style="overflow-y: scroll; overflow-x: scroll; height:1400px; padding:5px"
                >
                  <div class="row">
                    <div class="col-3 h5 text-secondary">
                      {{ $t("profile.name") }}
                      <!-- {{ languaje == "es" ? "Nombre" : "Name" }} -->
                    </div>
                    <div class="col-3 h5 text-secondary">
                      {{ $t("profile.phone") }}
                      <!-- {{ languaje == "es" ? "Telefono" : "Phone" }} -->
                    </div>
                    <div class="col-3 h5 text-secondary">
                      {{ $t("profile.email") }}
                      <!-- {{ languaje == "es" ? "Correo" : "Email" }} -->
                    </div>
                    <div class="col-3 h5 text-secondary">
                      {{ $t("profile.options") }}
                      <!-- {{ languaje == "es" ? "Opciones" : "Options" }} -->
                    </div>
                  </div>
                  <hr class="mt-0" />
                  <div
                    v-for="(driver, key) of driversSave"
                    :key="key"
                    class="mt-3 "
                  >
                    <div
                      v-if="
                        (driver
                          .get('driver')
                          .get('fullName')
                          .includes(textFilter) ||
                          driver
                            .get('driver')
                            .get('phoneNumber')
                            .includes(textFilter) ||
                          driver
                            .get('driver')
                            .get('username')
                            .includes(textFilter)) &&
                          driver.get('vinculated') == true
                      "
                      class="row"
                    >
                      <div class="col-3" style="font-size:12px;">
                        {{ driver.get("driver").get("fullName") }}
                      </div>
                      <div class="col-3" style="font-size:12px;">
                        {{ driver.get("driver").get("phoneNumber") }}
                      </div>
                      <div class="col-3" style="font-size:12px;">
                        {{ driver.get("driver").get("username") }}
                      </div>
                      <div class="col-3">
                        <button
                          type="button"
                          @click="desvincularDriver(driver)"
                          style="font-size:12px;"
                          class="btn btn-danger"
                        >
                          {{ $t("profile.unvinculate") }}
                          <!-- {{ languaje == "es" ? "Desvincular" : "Unvinculate" }} -->
                        </button>
                        <button
                          type="button"
                          @click="activateDriver(driver)"
                          style="font-size:12px;"
                          v-bind:class="{
                            'btn btn-danger mt-2':
                              driver.get('active') == false,
                            'btn btn-primary mt-2': driver.get('active') == true
                          }"
                        >
                          {{
                            languaje == "es"
                              ? driver.get("active")
                                ? "Activar"
                                : "Desactivar"
                              : driver.get("active")
                              ? "Enable"
                              : "Disable"
                          }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-4" v-else>
                <div
                  class="totalCard bg-white"
                  style="overflow-y: scroll; overflow-x: scroll; height:1400px; padding:5px"
                >
                  <div class="row ml-4 h4">
                    <div class="col-1 h5 text-secondary"></div>
                    <div class="col-5 h5 text-secondary">
                      {{ $t("profile.user") }}
                      <!-- {{ languaje == "es" ? "Usuario" : "User" }} -->
                    </div>
                    <div class="col-6 h5 text-secondary">
                      {{ $t("profile.options") }}
                      <!-- {{ languaje == "es" ? "Opciones" : "Options" }} -->
                    </div>
                  </div>
                  <hr class="mt-0" />
                  <div v-for="(user, key) of users" :key="key">
                    <div
                      v-if="
                        (user.attributes.name.includes(textFilter) ||
                          user.attributes.email.includes(textFilter) ||
                          user.attributes.last.includes(textFilter)) &&
                        user.attributes.email != usuarioFarmacia.email &&
                        roleFilter == 'A'
                          ? !user.attributes.admin
                          : user.attributes.admin
                      "
                      class="row mt-3 "
                    >
                      <div class="col-1 text-center">
                        <a v-if="user.attributes.admin">&#11088;</a>
                      </div>
                      <div class="col-5">
                        <p>
                          {{ user.attributes.name }} {{ user.attributes.last }}
                        </p>
                        <p style="font-size: 10px;">
                          {{ user.attributes.email }}
                        </p>
                        <p>{{ user.attributes.phone }}</p>
                      </div>
                      <div class="col-6">
                        <button
                          class="btn btn-info mr-1 mb-1 btnx"
                          @click="restorePassword(user)"
                        >
                          {{ $t("profile.password2") }}
                          <!-- {{ languaje == "es" ? "Contraseña" : "Password" }} -->
                        </button>
                        <button
                          v-bind:class="{
                            'btn btn-success mr-1 mb-1 btnx': !user.attributes
                              .status,
                            'btn btn-warning mr-1 mb-1 btnx':
                              user.attributes.status
                          }"
                          @click="disableUser(user)"
                        >
                          {{
                            user.attributes.status
                              ? languaje == "es"
                                ? "Inactivo"
                                : "Disable"
                              : languaje == "es"
                              ? "Activo"
                              : "Enable"
                          }}
                        </button>
                        <button
                          class="btn btn-danger mb-1 mr-1 btnx"
                          @click="deleteUser(user)"
                        >
                          {{ $t("profile.delete") }}
                          <!-- {{ languaje == "es" ? "Eliminar" : "Delete" }} -->
                        </button>
                        <button
                          @click="adminUser(user)"
                          v-bind:class="{
                            'btn btn-primary mr-1 mb-1 btnx': !user.attributes
                              .admin,
                            'btn btn-secondary mr-1 mb-1 btnx':
                              user.attributes.admin
                          }"
                        >
                          <a
                            v-bind:class="{
                              minLabel: !user.attributes.admin
                            }"
                            >{{
                              user.attributes.admin
                                ? languaje == "es"
                                  ? " Auxiliar"
                                  : " Assistant"
                                : languaje == "es"
                                ? " Administrador"
                                : " Administrator"
                            }}</a
                          >
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- modal agregar usuario -->
      <div
        class="modal fade"
        style="overflow:hidden !important"
        id="modalAddUsuarios"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class=" modal-position modal-dialog modal-dialog-centered"
          role="document"
        >
          <div
            style="overflow-y:auto;border-radius:0"
            class="modal-content add-product-form modalContainer"
          >
            <div class="modal-header">
              <h5
                class="modal-title text-center"
                id="exampleModalLongTitle"
                style="font-family: 'Montserrat', sans-serif;"
              >
                {{
                  addUser
                    ? languaje == "es"
                      ? "Agregar Usuario"
                      : "Add User"
                    : languaje == "es"
                    ? "Modificar mi Usuario"
                    : "Modify my User"
                }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="cancelAddUser"
              >
                <span aria-hidden="true" class="h1 bold">&times;</span>
              </button>
            </div>
            <div class="modal-body specialBody">
              <form>
                <div class="form-group">
                  <label
                    ><i
                      class="fa fa-asterisk asteric"
                      v-if="addUser"
                      style="size: 10px; color:#a50000ad;"
                      aria-hidden="true"
                    ></i>
                    {{ $t("profile.userName") }}:
                    <!-- {{
                      languaje == "es" ? "Nombre de Usuario" : "User Name"
                    }}: -->
                  </label>
                  <input
                    type="text"
                    v-model="Name"
                    class="form-control"
                    id="nombre"
                  />
                  <p class="alertDanger" v-if="esName">
                    {{ $t("profile.requiredName") }}:
                    <!-- {{
                      languaje == "es"
                        ? "Nombre es Requerido"
                        : "Name is Required"
                    }} -->
                  </p>
                </div>
                <div class="form-group">
                  <label
                    ><i
                      class="fa fa-asterisk asteric"
                      v-if="addUser"
                      style="size: 10px; color:#a50000ad;"
                      aria-hidden="true"
                    ></i>
                    {{ $t("profile.lastName") }}:
                    <!-- {{
                      languaje == "es"
                        ? "Apellido de Usuario:"
                        : "User Last Name:"
                    }} -->
                  </label>
                  <input
                    type="text"
                    v-model="Last"
                    class="form-control"
                    id="lastname"
                  />
                  <p class="alertDanger" v-if="esLast">
                    {{ $t("profile.requireLastName") }}
                    <!-- {{
                      languaje == "es"
                        ? "Apellido es Requerido"
                        : "Last Name is Required"
                    }} -->
                  </p>
                </div>
                <div class="form-group">
                  <label
                    ><i
                      class="fa fa-asterisk asteric"
                      v-if="addUser"
                      style="size: 10px; color:#a50000ad;"
                      aria-hidden="true"
                    ></i>
                    {{ $t("profile.userPhone") }}:
                    <!-- {{
                      languaje == "es" ? "Telefono de Usuario:" : "User Phone:"
                    }} -->
                  </label>
                  <input
                    type="tel"
                    v-model="Phone"
                    class="form-control"
                    id="telefono"
                  />
                  <p class="alertDanger" v-if="esPhone">
                    {{ $t("profile.requiredUserPhone") }}:
                    <!-- {{
                      languaje == "es"
                        ? "Telefono is Required"
                        : "Phone is Required"
                    }} -->
                  </p>
                </div>
                <div class="form-group">
                  <label
                    ><i
                      class="fa fa-asterisk asteric"
                      v-if="addUser"
                      style="size: 10px; color:#a50000ad;"
                      aria-hidden="true"
                    ></i>
                    {{ $t("profile.userEmail") }}:
                    <!-- {{
                      languaje == "es"
                        ? "Correo Electronico de Usuario:"
                        : "User Email:"
                    }} -->
                  </label>
                  <input
                    type="email"
                    v-model="Email"
                    class="form-control"
                    id="correo"
                  />
                  <p class="alertDanger" v-if="esEmail">
                    {{ $t("profile.requiredUserEmail") }}
                    <!-- {{
                      languaje == "es"
                        ? "Correo Electronico es Requerido"
                        : "Email is Required"
                    }} -->
                  </p>
                </div>
                <div class="form-group" v-if="!addUser">
                  <label
                    ><i
                      class="fa fa-asterisk asteric"
                      v-if="addUser"
                      style="size: 10px; color:#a50000ad;"
                      aria-hidden="true"
                    ></i>
                    {{ $t("profile.userPassword") }}:
                    <!-- {{
                      languaje == "es"
                        ? "Contraseña de Usuario:"
                        : "User Password:"
                    }} -->
                  </label>
                  <input
                    type="password"
                    v-model="Password"
                    class="form-control"
                    id="password"
                  />
                  <p class="alertDanger" v-if="esPassword">
                    {{ $t("profile.requiredUserPassword") }}:
                    <!-- {{
                      languaje == "es"
                        ? "Contraseña es Requerida"
                        : "Password is Required"
                    }} -->
                  </p>
                </div>
                <div class="form-group">
                  <label
                    ><i
                      class="fa fa-asterisk asteric"
                      v-if="addUser"
                      style="size: 10px; color:#a50000ad;"
                      aria-hidden="true"
                    ></i>
                    {{ $t("profile.userRole") }}:
                    <!-- {{
                      languaje == "es" ? "Rol de Usuario:" : "User Role:"
                    }} -->
                  </label>
                  <select
                    v-model="Role"
                    :disabled="!addUser"
                    class="form-control"
                    id="rol"
                  >
                    <option
                      v-for="(rol, key) of roles"
                      :key="key"
                      :value="rol.id"
                      >{{ rol.descripcion }}</option
                    >
                  </select>
                  <p class="alertDanger" v-if="esRole">
                    {{ $t("profile.requiredUserRole") }}
                    <!-- {{
                      languaje == "es" ? "Rol es Requerido" : "Role is Required"
                    }} -->
                  </p>
                </div>
                <div class="form-group mt-3">
                  <button
                    type="button"
                    @click="processUser"
                    class="btn btn-primary btn-lg btn-block"
                    id="btnGuardar"
                  >
                    {{
                      addUser
                        ? languaje == "es"
                          ? "Procesar Usuario"
                          : "Proccess User"
                        : languaje == "es"
                        ? "Modificar mi Usuario"
                        : "Modify my User"
                    }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import Parse from "parse";
import { setTimeout } from "timers";
import Mapbox from "mapbox-gl-vue";
import Header from "../../components/Header";
const options = {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674"
};
Vue.use(VueSweetalert2, options);

export default {
  name: "Profile",
  components: { NavBar, Mapbox, Header },
  data() {
    return {
      changeCiudades: false,
      usuarioFarmacia: {},
      textFilter: "",
      result: false,
      // hours: [
      //     { hora: "12:00AM" },{ hora: "12:30AM" },{ hora: "01:00AM" },{ hora: "01:30AM" },{ hora: "02:00AM" },{ hora: "02:30AM" },{ hora: "03:00AM" },{ hora: "03:30AM" },{ hora: "04:00AM" },{ hora: "04:30AM" },{ hora: "05:00AM" },{ hora: "05:30AM" },
      //     { hora: "06:30AM" },{ hora: "07:00AM" },{ hora: "07:30AM" },{ hora: "08:00AM" },{ hora: "08:30AM" },{ hora: "09:00AM" },{ hora: "09:30AM" },{ hora: "10:00AM" },{ hora: "10:30AM" },{ hora: "11:00AM" },{ hora: "11:30AM" },{ hora: "12:00PM" },
      //     { hora: "12:30PM" },{ hora: "01:00PM" },{ hora: "01:30PM" },{ hora: "02:00PM" },{ hora: "02:30PM" },{ hora: "03:00PM" },{ hora: "03:30PM" },{ hora: "04:00PM" },{ hora: "04:30PM" },{ hora: "05:00PM" },{ hora: "05:30PM" },{ hora: "06:30PM" },
      //     { hora: "07:00PM" },{ hora: "07:30PM" },{ hora: "08:00PM" },{ hora: "08:30PM" },{ hora: "09:00PM" },{ hora: "09:30PM" },{ hora: "10:00PM" },{ hora: "10:30PM" },{hora: "11:00PM"},{hora: "11:30PM"},
      // ],
      diverPrice: 0,
      roles: [],
      drivers: [],
      driversSave: [],
      searchCity: "",
      farmaceutico: "",
      addUser: true,
      stores: [],
      userrs: [{}],
      users: [],
      categorias: [],
      roleFilter: "A",
      esRole: false,
      Admin: false,
      esAdmin: false,
      Name: "",
      esName: false,
      Last: "",
      esLast: false,
      Phone: "",
      esPhone: false,
      Email: "",
      esEmail: false,
      Role: "A",
      //   esRole: false,
      backdrop: false,
      loader: false,
      email: "",
      Password: "",
      esPassword: false,
      password: "",
      nameStore: "",
      phoneNumber: "",
      address: "",
      category: "",
      categoryList: [],
      city: "",
      zipcodeList: null,
      gps: "",
      lat: 0,
      lon: 0,
      location: null,
      alertMessage: "",
      picture: null,
      pictureUrl: null,
      // status switch
      statusSun: false,
      statusMon: false,
      statusTue: false,
      statusWed: false,
      statusThu: false,
      statusFri: false,
      statusSat: false,
      // am valu
      SundayAM: "",
      MondayAM: null,
      TuesdayAM: "",
      WednesdayAM: "",
      ThursdayAM: "",
      FridayAM: "",
      SaturdayAM: "",
      //  pm Value
      SundayPM: "",
      MondayPM: "",
      TuesdayPM: "",
      WednesdayPM: "",
      ThursdayPM: "",
      FridayPM: "",
      SaturdayPM: "",
      // array
      schedule: null,
      buttonChane: "",
      status: false,
      colorMonday: "",
      colorTuesday: "",
      colorWednesday: "green",
      colorThursday: "",
      colorFriday: "",
      colorSaturday: "",
      validate: "",
      isProcessing: false,
      allZipCodes: null,
      zipcodes: null,
      categories: [],
      stripeUserId: null,
      info: null,
      superAdmin: null,
      admin: null,
      deliverySystem: "",
      query: new Parse.Query("Store"),
      languaje: "",
      esStripe: false
    };
  },
  watch: {
    categoryList() {
      this.searchCity = this.categoryList[0].attributes.name;
    },
    picture() {
      this.loader = false;
      this.backdrop = false;
    },
    searchCity() {
      if (this.searchCity === "") {
        this.getCategories();
      } else {
        let ciudades = [];
        for (let category of this.categories) {
          if (
            category.attributes.name
              .toLowerCase()
              .includes(this.searchCity.toLowerCase())
          ) {
            ciudades.push(category);
          }
        }
        this.categories = ciudades;
      }
    },
    diverPrice() {
      if (this.diverPrice < 2) {
        this.diverPrice = 4;
      }
    }
  },
  mounted() {
    this.languaje = navigator.language || navigator.userLanguage;
    this.languaje = this.languaje.substr(0, 2);
    if (this.languaje == "es") {
      this.roles = [
        { descripcion: "Auxiliar", id: "A" },
        { descripcion: "Administrador", id: "AA" },
        { descripcion: "Driver", id: "E" }
      ];
    } else {
      this.roles = [
        { descripcion: "Assistant", id: "A" },
        { descripcion: "Administrator", id: "AA" },
        { descripcion: "Driver", id: "E" }
      ];
    }

    this.getStoreId();
    this.seeUserStore();
    this.superAdmin = Parse.User.current().get("superAdmin");
    this.admin = Parse.User.current().get("isAdmin");
    if (!this.admin && !this.superAdmin) {
      this.$router.push("/");
    }

    if (!this.admin && this.superAdmin) {
      this.$router.push("/adminStores");
    }

    this.backdrop = true;
    this.loader = true;
    const user = Parse.User.current();
    if (user === null) {
      this.$router.push("/HelloWorld");
    }

    if (user.get("stripeAccountId")) {
      this.stripeUserId = user.get("stripeAccountId");
      this.esStripe = true;
    } else {
      if (localStorage.getItem("stripeToken")) {
        if (localStorage.getItem("stripeToken").substr(0, 2) == "ac") {
          this.stripeUserId = localStorage.getItem("stripeToken");
          this.esStripe = true;
        } else {
          this.esStripe = false;
        }
      }
    }

    if (user.get("stripeAccountId") === "null") {
      this.esStripe = false;
    }
    this.getInfo();
    this.status = true;
    this.statusFri = true;
    this.statusMon = true;
    this.statusWed = true;
    this.statusSat = true;
    this.statusTue = true;
    this.statusThu = true;
    this.statusSun = true;
  },
  methods: {
    onChangeCiudades() {
      if (this.changeCiudades) {
        this.changeCiudades = false;
      } else {
        this.changeCiudades = true;
      }
    },
    async getStoreId() {
      await Parse.Cloud.run("getStore", {
        userId: Parse.User.current().id
      }).then(result => {
        this.pictureUrl = result.attributes.storePicture._url;
      });
    },
    findUser() {
      if (this.seeStore(arguments[0]) || this.seeUsers(arguments[0])) {
        this.result = true;
      }
    },
    openAddModal(val) {
      this.addUser = val;
      if (!this.addUser) {
        this.Name = this.usuarioFarmacia.name;
        this.Last = this.usuarioFarmacia.last;
        this.Phone = this.usuarioFarmacia.phone;
        this.Email = this.usuarioFarmacia.email;
        this.Password = atob(this.usuarioFarmacia.crapld);
        this.Role = this.usuarioFarmacia.rol;
        this.Admin = this.usuarioFarmacia.admin;
      } else {
        this.Name = "";
        this.Last = "";
        this.Phone = "";
        this.Email = "";
        this.Password = Math.random()
          .toString(36)
          .substring(2);
        this.Role = "A";
        this.Admin = false;
      }
    },
    seeStore() {
      let result;
      const MyCustomClass = Parse.Object.extend("Store");
      const query = new Parse.Query(MyCustomClass);
      query.equalTo("isApproved", true);
      query.find().then(results => {
        for (let result of results) {
          if (result.attributes.users) {
            for (let user of result.attributes.users) {
              if (arguments[0] === user.email) {
                this.result = true;
              }
            }
          }
        }
        this.status;
      });
      return result;
    },
    seeUsers() {
      let result;
      const MyCustomClass = Parse.Object.extend("_User");
      const query = new Parse.Query(MyCustomClass);
      query.find().then(results => {
        for (let result of results) {
          if (arguments[0] === result.attributes.username) {
            this.result = true;
          }
        }
      });
      return result;
    },

    restorePassword(user) {
      let password = Math.random()
        .toString(36)
        .substring(2);
      user.set("password", btoa(password));
      user.set(this.users);
      user.save().then(
        () => {
          this.sendEmailRestoreOperario({
            Nombre: user.get("name") + " " + user.get("last"),
            Password: password,
            Correo: user.get("email")
          });
          this.openAlertModal(
            this.languaje == "es" ? "Hecho" : "Done",
            this.languaje == "es"
              ? "Contraseña de Usuario fue enviado para cambio con exito"
              : "User password has been sent for changing successfully",
            "success"
          );
        },
        error => {
          console.log(error);
          this.openAlertModal(
            "Error",
            this.languaje == "es"
              ? "Ocurrio un error, favor vuelva a intentarlo."
              : "It has occurred an error, please try again",
            "error"
          );
        }
      );
      this.seeStoreUsers();
    },
    disableUser(user) {
      if (user.get("status")) {
        user.set("status", false);
      } else {
        user.set("status", true);
      }
      user.save().then(
        () => {
          this.openAlertModal(
            this.languaje == "es" ? "Hecho" : "Done",
            user.get("status")
              ? this.languaje == "es"
                ? "Usuario fue activado con exito"
                : "User has been enabled successfully"
              : this.languaje == "es"
              ? "Usuario fue desactivado con exito"
              : "User has been disabled succesfully",
            "success"
          );
        },
        error => {
          console.log(error);
          this.openAlertModal(
            "Error",
            this.languaje == "es"
              ? "Ocurrio un error, favor vuelva a intentarlo."
              : "It has occurred an error, please try again",
            "error"
          );
        }
      );
      this.seeStoreUsers();
    },
    adminUser(user) {
      if (user.get("admin")) {
        user.set("admin", false);
      } else {
        user.set("admin", true);
      }
      user.save().then(
        () => {
          this.openAlertModal(
            this.languaje == "es" ? "Hecho" : "Done",
            user.get("admin")
              ? this.languaje == "es"
                ? "Usuario es Administrador"
                : "User is Administrator"
              : this.languaje == "es"
              ? "Usuario no es Administrador"
              : "User is not Administrator",
            "success"
          );
        },
        error => {
          console.log(error);
          this.openAlertModal(
            "Error",
            this.languaje == "es"
              ? "Ocurrio un error, favor vuelva a intentarlo."
              : "It has occurred an error, please try again",
            "error"
          );
        }
      );
      this.seeStoreUsers();
    },
    deleteUser(user) {
      user.set("deleted", true);
      user.set("status", false);
      this.info.save().then(
        result => {
          console.log(result);
          this.openAlertModal(
            this.languaje == "es" ? "Hecho" : "Done",
            this.languaje == "es"
              ? "Usuario fue eliminado con exito"
              : "User was deleted successfully",
            "info"
          );
        },
        error => {
          console.log(error);
          this.openAlertModal(
            "Error",
            this.languaje == "es"
              ? "Ocurrio un error, favor vuelva a intentarlo."
              : "It has occurred an error, please try again",
            "error"
          );
        }
      );
      this.seeStoreUsers();
    },
    seeStoreUsers() {
      const query = new Parse.Query("storeUsers");
      query.include("store");
      query.include("deleted");
      query.equalTo(
        "store",
        Parse.Object.extend("Store")
          .createWithoutData(this.info.id)
          .toPointer()
      );
      query.equalTo("deleted", false);
      query.find().then(users => {
        this.users = users;
      });
    },
    desvincularDriver(driver) {
      driver.set("vinculated", false);
      driver.save().then(() => {
        this.getDriverStores();
      });
    },
    activateDriver(driver) {
      if (driver.get("active") === true) {
        driver.set("active", false);
      } else {
        driver.set("active", true);
      }
      driver.save();
      this.getDriverStores();
    },
    exiteUsuario(email) {
      const query = new Parse.Query("storeUsers");
      const query2 = new Parse.Query("_User");
      query2.equalTo("email", email);
      query2.first().then(data => {
        query.equalTo("email", email);
        query.first().then(apa => {
          if (apa || data) {
            localStorage.setItem("existe", "1");
          } else {
            localStorage.setItem("existe", "0");
          }
        });
      });
    },
    sendEmailOperario(cuerpoCorreo) {
      // eslint-disable-next-line
        Email.send({
        Host: "smtp.gmail.com",
        Username: "serviciotecnico@coopharma.coop",
        Password: "Mysh0p2020",
        To: cuerpoCorreo.Correo,
        From: "serviciotecnico@coopharma.coop",
        Subject: "Bienvenido a Coopharma",
        Body:
          "<div style='background-color:#E0E0E0; padding: 20px; width: 80%;'>" +
          "<a href='www.coopharma.coop'><img src='https://coopharma.app/img/logo-coopharma.e5f23e05.png'></a>" +
          "</div>" +
          "<div style='margin:40px; width:70%; text-align: justify;'>" +
          "<p style = 'font-size: 20px;'>Hola, " +
          cuerpoCorreo.Nombre +
          "</p>" +
          "<p style = 'font-size: 20px;'>Este es un correo de invitacion para el uso de la plataforma de " +
          "<a href='www.coopharma.app' style='color:#0E9390; font-weight:bold;'>www.coopharma.app</a>" +
          ", Para poder validar sus datos en la plataforma con las siguientes credenciales:</p>" +
          "<p style = 'font-size: 20px;'><b style='color:#0E9390;'>Usuario:</b> " +
          cuerpoCorreo.Correo +
          "<br>" +
          "<b style='color:#0E9390;'>Contraseña:</b> " +
          cuerpoCorreo.Password +
          "</p>" +
          "<p>Para poder ingresar acceda a la plataforma usando este enlace <a href='www.coopharma.app'>www.coopharma.app</a>,</p>" +
          "<p style = 'font-size: 20px;'>Una vez haya validado su usuario proceda en la opcion de perfil a cambiar su " +
          "contrasenia,<br>para cualquier duda o inconveniente puede llamar a (787) 520-6018<br>Saludos</p><div>" +
          "<a href='www.coopharma.app'><button style='width:100%;'><p>" +
          "<img src='https://coopharma.app/img/logo-coopharma.e5f23e05.png' style='width:150px;'>" +
          "<p style='font-size: 20px; font-weight:bold; color: #ED7422;'>Ir a la Plataforma</p></button></a></div></div>"
      });
    },
    sendEmailDriver(cuerpoCorreo) {
      // eslint-disable-next-line
        Email.send({
        Host: "smtp.gmail.com",
        Username: "serviciotecnico@coopharma.coop",
        Password: "Mysh0p2020",
        To: cuerpoCorreo.Correo,
        From: "serviciotecnico@coopharma.coop",
        Subject: "Bienvenido a Coopharma",
        Body:
          "<div style='background-color:#E0E0E0; padding: 20px; width: 80%;'>" +
          "<a href='www.coopharma.coop'><img src='https://coopharma.app/img/logo-coopharma.e5f23e05.png'></a>" +
          "</div>" +
          "<div style='margin:40px; width:70%; text-align: justify;'>" +
          "<p style = 'font-size: 20px;'>Hola, " +
          cuerpoCorreo.Nombre +
          "</p>" +
          "<p style = 'font-size: 20px;'>Este es un correo de invitacion para el uso de la plataforma de " +
          "<a href='www.coopharma.app' style='color:#0E9390; font-weight:bold;'>www.coopharma.app</a>" +
          ", Para poder validar sus datos en la plataforma con las siguientes credenciales de Driver:</p>" +
          "<p style = 'font-size: 20px;'><b style='color:#0E9390;'>Usuario:</b> " +
          cuerpoCorreo.Correo +
          "<br>" +
          "<b style='color:#0E9390;'>Contraseña:</b> " +
          cuerpoCorreo.Password +
          "</p>" +
          "<p style = 'font-size: 20px;'>Para seguir con el proceso de validacion proceda a completar el siguiente" +
          "formulario,<br>para cualquier duda o inconveniente puede llamar a (787) 520-6018<br>Saludos</p><div>" +
          "<a href='www.coopharma.app/#/validateDriver/" +
          cuerpoCorreo.DriverId +
          "'><button style='width:100%;'><p>" +
          "<img src='https://coopharma.app/img/logo-coopharma.e5f23e05.png' style='width:150px;'>" +
          "<p style='font-size: 20px; font-weight:bold; color: #ED7422;'>Ir a la Plataforma</p></button></a></div></div>"
      });
    },
    sendEmailRestoreOperario(cuerpoCorreo) {
      // eslint-disable-next-line
        Email.send({
        Host: "smtp.gmail.com",
        Username: "serviciotecnico@coopharma.coop",
        Password: "Mysh0p2020",
        To: cuerpoCorreo.Correo,
        From: "serviciotecnico@coopharma.coop",
        Subject: "Restablecer Password",
        Body:
          "<div style='background-color:#E0E0E0; padding: 20px; width: 80%;'>" +
          "<a href='www.coopharma.coop'><img src='https://coopharma.app/img/logo-coopharma.e5f23e05.png'></a>" +
          "</div>" +
          "<div style='margin:40px; width:70%; text-align: justify;'>" +
          "<p style = 'font-size: 20px;'>Hola, " +
          cuerpoCorreo.Nombre +
          "</p>" +
          "<p style = 'font-size: 20px;'>Este es un correo de reestablecimiento de usuario en la plataforma de " +
          "<a href='www.coopharma.app' style='color:#0E9390; font-weight:bold;'>www.coopharma.app</a>" +
          ", Para poder validar sus datos en la plataforma con las siguientes credenciales:</p>" +
          "<p style = 'font-size: 20px;'><b style='color:#0E9390;'>Usuario:</b> " +
          cuerpoCorreo.Correo +
          "<br>" +
          "<b style='color:#0E9390;'>Contraseña:</b> " +
          cuerpoCorreo.Password +
          "</p>" +
          "<p>Para poder ingresar acceda a la plataforma usando este enlace <a href='www.coopharma.app'>www.coopharma.app</a>,</p>" +
          "<p style = 'font-size: 20px;'>Una vez haya validado su usuario proceda en la opcion de perfil a cambiar su " +
          "contrasenia,<br>Saludos</p><div><a href='www.coopharma.app'><button style='width:100%;'><p>" +
          "<img src='https://coopharma.app/img/logo-coopharma.e5f23e05.png' style='width:150px;'>" +
          "<p style='font-size: 20px; font-weight:bold; color: #ED7422;'>Ir a la Plataforma</p></button></a></div></div>"
      });
    },
    processUser() {
      //   let usuarios = [];
      //   let usuario = {};
      // console.log(this.info);

      if (this.Name === "") {
        this.esName = true;
      } else {
        this.esName = false;
      }
      if (this.Last === "") {
        this.esLast = true;
      } else {
        this.esLast = false;
      }
      if (this.Phone === "") {
        this.esPhone = true;
      } else {
        this.esPhone = false;
      }
      if (this.Email === "") {
        this.esEmail = true;
      } else {
        this.esEmail = false;
      }
      if (this.Password === "" || this.Password.length < 6) {
        this.esPassword = true;
      } else {
        this.esPassword = false;
      }
      if (this.Role === "") {
        this.esRole = true;
      } else {
        this.esRole = false;
      }

      if (
        !this.esPassword &&
        !this.esRole &&
        !this.esName &&
        !this.esPhone &&
        !this.esEmail
      ) {
        if (this.Role === "E") {
          let User = Parse.Object.extend("_User");
          let user = new User();
          user.set("firstName", this.Name);
          user.set("lastName", this.Last);
          user.set("fullName", this.Name + " " + this.Last);
          user.set("phoneNumber", this.Phone);
          user.set("email", this.Email);
          user.set("username", this.Email);
          user.set("role", this.Role);
          user.set("password", this.Password);
          user.set("superAdmin", false);
          user.set("isAdmin", false);
          user.set("isApproved", false);
          user.set("isActivate", false);
          //   let drivers = [];
          user.signUp().then(
            result => {
              this.vinculateDriver(result.id);
              this.sendEmailDriver({
                Nombre: this.Name + " " + this.Last,
                Password: this.Password,
                Correo: this.Email,
                DriverId: result.id
              });
              this.Name = "";
              this.Last = "";
              this.Phone = "";
              this.Email = "";
              this.Password = "";
              this.Admin = false;
              this.Role = "A";
              this.openAlertModal(
                this.languaje == "es" ? "Guardado" : "Saved",
                this.languaje == "es"
                  ? "Driver fue agregado con exito"
                  : "Driver has been added successfully",
                "success"
              );
            },
            error => {
              console.log(error);
              const query = new Parse.Query("_User");
              query.equalTo("username", this.Email);
              query.first().then(driver => {
                // console.log(driver.id);
                this.vinculateDriver(driver.id);
              });

              this.Name = "";
              this.Last = "";
              this.Phone = "";
              this.Email = "";
              this.Password = "";
              this.Admin = false;
              this.Role = "A";
              this.openAlertModal(
                this.languaje == "es"
                  ? "Verifique el Usuario"
                  : "Verify the User",
                this.languaje == "es"
                  ? "Usuario existente, valide si ha sido agregado a su farmacia."
                  : "Existent User, make sure if it has been added to your Pharmacy",
                "info"
              );
            }
          );
        } else {
          this.exiteUsuario(this.Email);
          this.loader = true;
          this.backdrop = true;
          setTimeout(() => {
            if (
              localStorage.getItem("existe") === 1 ||
              this.Email === this.email
            ) {
              this.openAlertModal(
                this.languaje == "es" ? "Usuario Existente" : "Existent User",
                this.languaje == "es"
                  ? "Este Usuario ya esta siendo utilizado"
                  : "This user is already being used",
                "info"
              );
            } else {
              if (!this.addUser) {
                const query = new Parse.Query("storeUsers");
                query.equalTo(
                  "email",
                  JSON.parse(localStorage.getItem("usuarioFarmacia")).email
                );
                query.equalTo(
                  "password",
                  JSON.parse(localStorage.getItem("usuarioFarmacia")).crapld
                );
                query.equalTo(
                  "store",
                  Parse.Object.extend("Store")
                    .createWithoutData(this.info.id)
                    .toPointer()
                );
                query.first().then(user => {
                  user.set("name", this.Name);
                  user.set("last", this.Last);
                  user.set("phone", this.Phone);
                  user.set("email", this.Email);
                  user.set("password", btoa(this.Password));
                  user
                    .save()
                    .then(() => {
                      localStorage.setItem(
                        "usuarioFarmacia",
                        JSON.stringify({
                          name: user.get("name"),
                          last: user.get("last"),
                          admin: user.get("admin"),
                          email: user.get("email"),
                          crapld: user.get("password"),
                          phone: user.get("phone"),
                          rol: user.get("rol"),
                          status: user.get("status"),
                          editable: true
                        })
                      );
                      this.usuarioFarmacia = JSON.parse(
                        localStorage.getItem("usuarioFarmacia")
                      );
                      this.openAlertModal(
                        this.languaje == "es" ? "Actualizado" : "Updated",
                        this.languaje == "es"
                          ? "Usuario fue actualizado con exito"
                          : "User has been updated successfully",
                        "success"
                      );
                    })
                    .catch(() => {
                      this.openAlertModal(
                        "Error",
                        this.languaje == "es"
                          ? "Intente de nuevo"
                          : "Try Again",
                        "error"
                      );
                    });
                });
              } else {
                let storeUsers = Parse.Object.extend("storeUsers");
                let user = new storeUsers();
                user.set("name", this.Name);
                user.set("last", this.Last);
                user.set("phone", this.Phone);
                user.set("email", this.Email);
                user.set("password", btoa(this.Password));
                user.set("rol", "A");
                user.set("status", true);
                user.set("deleted", false);
                user.set("admin", this.Role === "AA" ? true : false);
                user.set(
                  "store",
                  Parse.Object.extend("Store")
                    .createWithoutData(this.info.id)
                    .toPointer()
                );
                user.save().then(
                  result => {
                    this.sendEmailOperario({
                      Nombre: this.Name + " " + this.Last,
                      Password: this.Password,
                      Correo: this.Email,
                      DriverId: result.id
                    });
                    this.Name = "";
                    this.Last = "";
                    this.Phone = "";
                    this.Email = "";
                    this.Password = "";
                    this.Admin = false;
                    this.Role = "A";
                    this.openAlertModal(
                      this.languaje == "es" ? "Guardado" : "Saved",
                      this.languaje == "es"
                        ? "Usuario fue agregado a esta farmacia con exito"
                        : "User has been added to this pharmacy successfully",
                      "success"
                    );
                  },
                  error => {
                    console.log(error);
                    this.Name = "";
                    this.Last = "";
                    this.Phone = "";
                    this.Email = "";
                    this.Password = "";
                    this.Admin = false;
                    this.Role = "A";
                    this.openAlertModal(
                      this.languaje == "es"
                        ? "Verifique el Usuario"
                        : "Verify the user",
                      this.languaje == "es"
                        ? "Valide si ha sido agregado a su Farmacia."
                        : "Make sure if has been added to your pharmacy",
                      "info"
                    );
                  }
                );
              }
            }
            this.loader = false;
            this.backdrop = false;
          }, 2000);
        }
      }
    },
    vinculateDriver(id) {
      const Driver = Parse.Object.extend("storeDrivers");
      const driver = new Driver();
      driver.set(
        "driver",
        Parse.Object.extend("_User")
          .createWithoutData(id)
          .toPointer()
      );
      driver.set(
        "store",
        Parse.Object.extend("Store")
          .createWithoutData(this.info.id)
          .toPointer()
      );
      driver.set("working", false);
      driver.set("vinculated", true);
      driver.set("active", false);
      driver
        .save()
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
      this.getDriverStores();
    },
    seeUserStore() {
      if (JSON.parse(localStorage.getItem("usuarioFarmacia"))) {
        this.usuarioFarmacia = JSON.parse(
          localStorage.getItem("usuarioFarmacia")
        );
      }
    },
    cancelAddUser() {
      this.Name = "";
      this.Phone = "";
      this.Email = "";
      this.Password = "";
      this.Role = "A";
      this.Admin = false;
      this.esName = false;
      this.esPhone = false;
      this.esEmail = false;
      this.esPassword = false;
      this.esRole = false;
    },
    mostrarboletin() {
      this.$swal({
        title: "AVISO",
        text: "No es posible en estos momentos, intente mas tarde",
        type: "info"
      });
    },
    openAlertModal(title, message, type) {
      this.alertMessage = message;
      this.$swal({
        title: title,
        text: message,
        type: type
      });
    },
    getZipCodes() {
      Parse.Cloud.run("getZipCodes").then(result => {
        this.zipcodes = result;
        setTimeout(this.zipcodesUI, 100);
      });
    },
    getCategories() {
      Parse.Cloud.run("getCategories").then(result => {
        this.categories = result;
        setTimeout(this.categoriesUI, 100);
      });
    },
    categoriesUI() {
      const inputs = document
        .getElementById("categories-list")
        .getElementsByTagName("input");
      const cats = this.info.get("categoryList");
      for (let input of inputs) {
        let catId = input.getAttribute("id");
        for (let cat of cats) {
          if (cat.id === catId) {
            input.checked = true;
            break;
          }
        }
      }
    },
    zipcodesUI() {
      const inputs = document
        .getElementById("zipcodes-list")
        .getElementsByTagName("input");
      const zips = this.info.get("zipcodes");
      for (let input of inputs) {
        let zipCode = input.value;
        for (let zip of zips) {
          if (zip === zipCode) {
            input.checked = true; // check input
            break;
          }
        }
      }
    },
    getLatLong() {
      let answer = true;
      this.location = null;
      if (
        this.lat === null ||
        this.lat === "" ||
        this.lon === null ||
        this.lon === ""
      ) {
        this.openAlertModal(
          this.languaje == "es" ? "Espera" : "Wait",
          this.languaje == "es"
            ? "Localizacion de Farmacia es requerida"
            : "Pharmacy Location is required.",
          "warning"
        );
        answer = false;
      }
      if (isNaN(this.lat) || isNaN(this.lon)) {
        this.openAlertModal(
          this.languaje == "es" ? "Espera" : "Wait",
          this.languaje == "es"
            ? "Ubicacion de Farmacia, latitud y longitud invalidas"
            : "Pharmacy location, latitude and longitude is invalid.",
          "error"
        );
        answer = false;
      }
      try {
        this.location = new Parse.GeoPoint(this.lat, this.lon);
      } catch (e) {
        this.openAlertModal(
          this.languaje == "es" ? "Espera" : "Wait",
          this.languaje == "es"
            ? "Ubicacion de Farmacia, latitud y longitud invalidas"
            : "Pharmacy location, latitude and longitude is invalid.",
          "error"
        );
        answer = false;
      }
      return answer;
    },
    getInfo() {
      Parse.Cloud.run("getStore", {
        userId: Parse.User.current().id
      })
        .then(result => {
          this.info = result;
          this.checkInfo();
        })
        .then(() => {
          this.backdrop = false;
          this.loader = false;
        });
    },
    async locateMe() {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(function(position) {
          localStorage.setItem(
            "location",
            JSON.stringify({
              latitud: position.coords.latitude,
              longitud: position.coords.longitude
            })
          );
        });
      } else {
        this.openAlertModal(
          this.languaje == "es" ? "Aviso" : "Advice",
          this.languaje == "es"
            ? "El buscador no soporta Geolocalización"
            : "Browser doesn't support geolocation!",
          "info"
        );
      }
    },
    logOut() {
      Parse.User.logOut().then(resp => {
        console.log(resp);
        localStorage.removeItem("usuarioFarmacia");
        localStorage.removeItem("stripeToken");
        localStorage.removeItem("archivoEmail");
        this.$router.push("/");
      });
    },
    getSchedule() {
      let schedule = [];
      let myObj = {};
      //  monday
      if (
        this.MondayAM != null &&
        this.MondayPM != null &&
        this.statusMon === true
      ) {
        myObj = {
          day: "Monday",
          start: this.MondayAM,
          end: this.MondayPM
        };
        schedule.push(myObj);
      }
      // tuesday
      if (
        this.TuesdayAM != null &&
        this.TuesdayPM != null &&
        this.statusTue === true
      ) {
        myObj = {
          day: "Tuesday",
          start: this.TuesdayAM,
          end: this.TuesdayPM
        };
        schedule.push(myObj);
      }
      // wednesday
      if (
        this.WednesdayAM != null &&
        this.WednesdayPM != null &&
        this.statusWed === true
      ) {
        myObj = {
          day: "Wednesday",
          start: this.WednesdayAM,
          end: this.WednesdayPM
        };
        schedule.push(myObj);
      }
      // thursday
      if (
        this.ThursdayAM != null &&
        this.ThursdayPM != null &&
        this.statusThu === true
      ) {
        myObj = {
          day: "Thursday",
          start: this.ThursdayAM,
          end: this.ThursdayPM
        };
        schedule.push(myObj);
      }
      // friday
      if (
        this.FridayAM != null &&
        this.FridayPM != null &&
        this.statusFri === true
      ) {
        myObj = {
          day: "Friday",
          start: this.FridayAM,
          end: this.FridayPM
        };
        schedule.push(myObj);
      }
      if (
        this.SaturdayAM != null &&
        this.SaturdayPM != null &&
        this.statusSat === true
      ) {
        myObj = {
          day: "Saturday",
          start: this.SaturdayAM,
          end: this.SaturdayPM
        };
        schedule.push(myObj);
      }
      if (
        this.SundayAM != null &&
        this.SundayPM != null &&
        this.statusSun === true
      ) {
        myObj = {
          day: "Sunday",
          start: this.SundayAM,
          end: this.SundayPM
        };
        schedule.push(myObj);
      }
      return schedule;
    },
    editInfo() {
      // setInterval(() => {
      //   const inputs = document
      //     .getElementById("categories-list")
      //     .getElementsByTagName("input");
      if (this.city === "") {
        this.openAlertModal(
          this.languaje == "es" ? "Espera" : "Wait",
          this.languaje == "es"
            ? "Por Favor selecciona almenos una ciudad"
            : "Please select at least one city.",
          "warning"
        );
        return;
      }
      const gpsValid = this.getLatLong();
      let nombre = this.farmaceutico.substr(0, this.farmaceutico.indexOf(" "));
      let apellido = this.farmaceutico.substr(
        this.farmaceutico.indexOf(" "),
        this.farmaceutico.lenght
      );
      if (!gpsValid || this.password < 6) {
        this.backdrop = false;
        this.loader = false;
        if (this.password < 6) {
          // aqui estoy
        }
      } else {
        //  Edit user
        const user = Parse.User.current();
        user.set("email", this.email);
        user.set("username", this.email);
        user.set("password", this.password);
        user.set("lastName", apellido);
        user.set("firstName", nombre);
        user.set("fullName", this.farmaceutico);
        if (this.stripeUserId) {
          user.set("stripeAccountId", this.stripeUserId);
        }
        user.set("userValidated", true);
        user.save();
        //  Store
        const store = this.info;
        let schedule = [];
        // set array
        schedule = this.getSchedule();
        if (
          this.nameStore === null ||
          this.nameStore === "" ||
          this.phoneNumber === null ||
          this.phoneNumber === "" ||
          schedule === null ||
          schedule === ""
        ) {
          this.backdrop = false;
          this.loader = false;
          this.swal({
            title: this.languaje == "es" ? "Atencion" : "Attention",
            text:
              this.languaje == "es"
                ? "Todos los campos son requeridos."
                : "All fields are required",
            type: "warning"
          });
          return;
        }
        store.set("Name", this.nameStore);
        store.set("Phone", parseInt(this.phoneNumber));
        store.set("Address", this.address);
        store.set("subCategories", this.category);
        store.set("Schedule", schedule);
        store.set("storePicture", this.picture);
        store.set("gps", this.location);
        store.set("logkey", btoa(this.password));
        if (this.city !== "") {
          store.set("city", this.city);
        }
        store.set("definedDelivery", this.deliverySystem);
        this.loader = false;
        this.backdrop = false;
        store.save().then(
          () => {
            this.backdrop = false;
            this.loader = false;
            localStorage.removeItem("stripeToken");
            localStorage.setItem(
              "usuarioFarmacia",
              JSON.stringify({
                admin: true,
                editable: false,
                email: this.email,
                name: nombre + " " + apellido,
                rol: "E",
                status: true,
                stripeAccountId: this.stripeUserId === "" ? false : true
              })
            );
            this.onChangeCiudades();
            this.getInfo();
            this.openAlertModal(
              this.languaje == "es" ? "Exito" : "Success",
              this.languaje == "es"
                ? "Actualización de su perfil completada, Vuelva Iniciar Sesión"
                : "Profile Updated Successfully, let's restart the current session",
              "success"
            );
          },
          error => {
            console.log(error);
            this.openAlertModal(
              "Error",
              this.languaje == "es"
                ? "Ocurrio un error, favor vuelva a intentarlo."
                : "It has occurred an error, please try again",
              "error"
            );
          }
        );
      }
      // }, 5000);
    },
    getDriverStores() {
      const query = new Parse.Query("storeDrivers");
      query.include("driver");
      query.include("store");
      query.equalTo(
        "store",
        Parse.Object.extend("Store")
          .createWithoutData(this.info.id)
          .toPointer()
      );
      query.find().then(drivers => {
        this.driversSave = drivers;
      });
    },
    checkInfo() {
      this.getCategories();
      this.address = this.info.get("Address");
      this.email = this.info.get("employee").get("email");
      this.nameStore = this.info.get("Name");
      this.phoneNumber = this.info.get("Phone");
      this.category = this.info.get("subCategories");
      this.picture = this.info.get("storePicture");
      this.password = atob(this.info.get("logkey"));
      this.deliverySystem = this.info.get("definedDelivery");
      this.getDriverStores();
      if (this.picture != null) {
        this.pictureUrl = this.picture.url();
      }
      if (this.info.get("gps") != null) {
        this.lat = this.info.get("gps").latitude;
        this.lon = this.info.get("gps").longitude;
      } else {
        this.lon = -66.5;
        this.lat = 18.25;
      }
      if (this.info.get("city")) {
        this.city = this.info.get("city");
      }
      this.farmaceutico = this.info.get("employee").get("fullName");
      // schedule
      const schedule = this.info.get("Schedule");
      for (let horario of schedule) {
        if (horario.day === "Sunday") {
          this.SundayAM = horario.start;
          this.SundayPM = horario.end;
          document.getElementById("sun").style.backgroundColor = "#1e1e1e";
          this.buttonChane = "#1e1e1e";
          this.status = false;
          this.statusSun = true;
          document.getElementById("sunAM").removeAttribute("disabled");
          document.getElementById("sunAM").value = horario.start;
          document.getElementById("sunPM").removeAttribute("disabled");
          document.getElementById("sunPM").value = horario.end;
          if (horario.start === "" && horario.end === "") {
            this.switchDay("Sunday", false);
          }
        }
        if (horario.day === "Monday") {
          this.MondayAM = horario.start;
          this.MondayPM = horario.end;
          document.getElementById("mon").style.backgroundColor = "#1e1e1e";
          this.colorMonday = "#1e1e1e";
          this.status = false;
          this.statusMon = true;
          document.getElementById("monAM").removeAttribute("disabled");
          document.getElementById("monAM").value = this.MondayAM;
          document.getElementById("monPM").removeAttribute("disabled");
          document.getElementById("monPM").value = this.MondayPM;
          if (horario.start === "" && horario.end === "") {
            this.switchDay("Monday", false);
          }
        }
        if (horario.day === "Tuesday") {
          this.TuesdayAM = horario.start;
          this.TuesdayPM = horario.end;
          document.getElementById("tue").style.backgroundColor = "#1e1e1e";
          this.colorTuesday = "#1e1e1e";
          this.status = false;
          this.statusTue = true;
          document.getElementById("tueAM").removeAttribute("disabled");
          document.getElementById("tueAM").value = this.TuesdayAM;
          document.getElementById("tuePM").removeAttribute("disabled");
          document.getElementById("tuePM").value = this.TuesdayPM;
          if (horario.start === "" && horario.end === "") {
            this.switchDay("Tuesday", false);
          }
        }
        if (horario.day === "Wednesday") {
          this.WednesdayAM = horario.start;
          this.WednesdayPM = horario.end;
          document.getElementById("wed").style.backgroundColor = "#1e1e1e";
          this.colorWednesday = "red";
          this.status = false;
          this.statusWed = true;
          document.getElementById("wedAM").removeAttribute("disabled");
          document.getElementById("wedAM").value = this.WednesdayAM;
          document.getElementById("wedPM").removeAttribute("disabled");
          document.getElementById("wedPM").value = this.WednesdayPM;
          if (horario.start === "" && horario.end === "") {
            this.switchDay("Wednesday", false);
          }
        }
        if (horario.day === "Thursday") {
          this.ThursdayAM = horario.start;
          this.ThursdayPM = horario.end;
          document.getElementById("thu").style.backgroundColor = "#1e1e1e";
          this.colorThursday = "#1e1e1e";
          this.status = false;
          this.statusThu = true;
          document.getElementById("thuAM").removeAttribute("disabled");
          document.getElementById("thuAM").value = this.ThursdayAM;
          document.getElementById("thuPM").removeAttribute("disabled");
          document.getElementById("thuPM").value = this.ThursdayPM;
          if (horario.start === "" && horario.end === "") {
            this.switchDay("Thursday", false);
          }
        }
        if (horario.day === "Friday") {
          this.FridayAM = horario.start;
          this.FridayPM = horario.end;
          document.getElementById("fri").style.backgroundColor = "#1e1e1e";
          this.colorFriday = "#1e1e1e";
          this.status = false;
          this.statusFri = true;
          document.getElementById("friAM").removeAttribute("disabled");
          document.getElementById("friAM").value = this.FridayAM;
          document.getElementById("friPM").removeAttribute("disabled");
          document.getElementById("friPM").value = this.FridayPM;
          if (horario.start === "" && horario.end === "") {
            this.switchDay("Friday", false);
          }
        }
        if (horario.day === "Saturday") {
          this.SaturdayAM = horario.start;
          this.SaturdayPM = horario.end;
          document.getElementById("sat").style.backgroundColor = "#1e1e1e";
          this.colorSaturday = "#1e1e1e";
          this.status = false;
          this.statusSat = true;
          document.getElementById("satAM").removeAttribute("disabled");
          document.getElementById("satAM").value = this.SaturdayAM;
          document.getElementById("satPM").removeAttribute("disabled");
          document.getElementById("satPM").value = this.SaturdayPM;
          if (horario.start === "" && horario.end === "") {
            this.switchDay("Saturday", false);
          }
        }
      }
    },
    onFileSelected(event) {
      if (event.path[0].files[0].size < 2097152) {
        this.loader = true;
        this.backdrop = true;

        // let self = this;
        const toBase64 = file =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });
        this.Main(toBase64);
        if (this.picture) {
          this.loader = false;
          this.backdrop = false;
        }
      } else {
        this.$swal({
          title: this.languaje == "es" ? "Atencíon" : "Attention",
          text:
            this.languaje == "es"
              ? "Esta imagen no es posible procesarla, elija otra por favor"
              : "This picture is not possible to proccess it, please select another one",
          type: "info"
        });
      }
    },
    Main: async function(toBase64) {
      const file = document.querySelector(".inputPicture").files[0];
      this.picture = await toBase64(file);
      const base64Image = this.picture;
      const name = "photo.jpeg";
      const parseFile = new Parse.File(name, {
        base64: base64Image
      });
      // convierte la foto a base64
      parseFile.save().then(savedFile => {
        this.picture = savedFile;
      });
    },
    switchDay(parameter, status) {
      this.buttonColor = "#FFF";
      if (parameter === "Sunday" && status === true) {
        document.getElementById("sun").style.backgroundColor = "#1e1e1e";
        this.buttonChane = "#1e1e1e";
        this.status = false;
        this.statusSun = true;
        document.getElementById("sunAM").removeAttribute("disabled");
        document.getElementById("sunPM").removeAttribute("disabled");
      }
      if (parameter === "Sunday" && status === false) {
        document.getElementById("sun").style.backgroundColor =
          "rgba(30, 30, 30, 0.67)";
        document.getElementById("sunAM").setAttribute("disabled", "disabled");
        document.getElementById("sunPM").setAttribute("disabled", "disabled");
        this.buttonChane = "#FFF";
        this.status = true;
        this.statusSun = false;
      }
      if (parameter === "Monday" && status === true) {
        document.getElementById("mon").style.backgroundColor = "#1e1e1e";
        this.colorMonday = "#1e1e1e";
        this.status = false;
        this.statusMon = true;
        document.getElementById("monAM").removeAttribute("disabled");
        document.getElementById("monPM").removeAttribute("disabled");
      }
      if (parameter === "Monday" && status === false) {
        document.getElementById("mon").style.backgroundColor =
          "rgba(30, 30, 30, 0.67)";
        document.getElementById("monAM").setAttribute("disabled", "disabled");
        document.getElementById("monPM").setAttribute("disabled", "disabled");
        this.colorMonday = "red";
        this.status = true;
        this.statusMon = false;
      }
      if (parameter === "Tuesday" && status === true) {
        document.getElementById("tue").style.backgroundColor = "#1e1e1e";
        this.colorTuesday = "#1e1e1e";
        this.status = false;
        this.statusTue = true;
        document.getElementById("tueAM").removeAttribute("disabled");
        document.getElementById("tuePM").removeAttribute("disabled");
      }
      if (parameter === "Tuesday" && status === false) {
        document.getElementById("tue").style.backgroundColor =
          "rgba(30, 30, 30, 0.67)";
        this.colorTuesday = "#FFF";
        this.status = true;
        this.statusTue = false;
        document.getElementById("tueAM").setAttribute("disabled", "disabled");
        document.getElementById("tuePM").setAttribute("disabled", "disabled");
      }
      if (parameter === "Wednesday" && status === true) {
        document.getElementById("wed").style.backgroundColor = "#1e1e1e";
        this.colorWednesday = "red";
        this.status = false;
        this.statusWed = true;
        document.getElementById("wedAM").removeAttribute("disabled");
        document.getElementById("wedPM").removeAttribute("disabled");
      }
      if (parameter === "Wednesday" && status === false) {
        document.getElementById("wed").style.backgroundColor =
          "rgba(30, 30, 30, 0.67)";
        this.colorWednesday = "blue";
        this.status = true;
        this.statusWed = false;
        document.getElementById("wedAM").setAttribute("disabled", "disabled");
        document.getElementById("wedPM").setAttribute("disabled", "disabled");
      }
      if (parameter === "Thursday" && status === true) {
        document.getElementById("thu").style.backgroundColor = "#1e1e1e";
        this.colorThursday = "#1e1e1e";
        this.status = false;
        this.statusThu = true;
        document.getElementById("thuAM").removeAttribute("disabled");
        document.getElementById("thuPM").removeAttribute("disabled");
      }
      if (parameter === "Thursday" && status === false) {
        document.getElementById("thu").style.backgroundColor =
          "rgba(30, 30, 30, 0.67)";
        this.colorThursday = "#FFF";
        this.status = true;
        this.statusThu = false;
        document.getElementById("thuAM").setAttribute("disabled", "disabled");
        document.getElementById("thuPM").setAttribute("disabled", "disabled");
      }
      if (parameter === "Friday" && status === true) {
        document.getElementById("fri").style.backgroundColor = "#1e1e1e";
        this.colorFriday = "#1e1e1e";
        this.status = false;
        this.statusFri = true;
        document.getElementById("friAM").removeAttribute("disabled");
        document.getElementById("friPM").removeAttribute("disabled");
      }
      if (parameter === "Friday" && status === false) {
        document.getElementById("fri").style.backgroundColor =
          "rgba(30, 30, 30, 0.67)";
        this.colorFriday = "#FFF";
        this.status = true;
        this.statusFri = false;
        document.getElementById("friAM").setAttribute("disabled", "disabled");
        document.getElementById("friPM").setAttribute("disabled", "disabled");
      }
      if (parameter === "Saturday" && status === true) {
        document.getElementById("sat").style.backgroundColor = "#1e1e1e";
        this.colorSaturday = "#1e1e1e";
        this.status = false;
        this.statusSat = true;
        document.getElementById("satAM").removeAttribute("disabled");
        document.getElementById("satPM").removeAttribute("disabled");
      }
      if (parameter === "Saturday" && status === false) {
        document.getElementById("sat").style.backgroundColor =
          "rgba(30, 30, 30, 0.67)";
        this.colorSaturday = "#FFF";
        this.status = true;
        this.statusSat = false;
        document.getElementById("satAM").setAttribute("disabled", "disabled");
        document.getElementById("satPM").setAttribute("disabled", "disabled");
      }
    },
    loaded(map) {
      console.log(map);
      if (this.lon !== 0 || this.lat !== 0) {
        // let marker =
        // eslint-disable-next-line
        new mapboxgl.Marker().setLngLat([this.lon, this.lat]).addTo(map);
      }
    },
    removeMarkers(map, e) {
      console.log(map, e);
      this.$router.push("storeProfile");
    },
    clicked(map, e) {
      confirm(
        this.languaje === "es"
          ? "Esta es la locación exacta de su farmacia?"
          : "Is this the exactly location of your pharmacy"
      );
      //   let marker =
      // eslint-disable-next-line
        new mapboxgl.Marker().setLngLat([e.lngLat.lng, e.lngLat.lat]).addTo(map);
      this.lon = e.lngLat.lng;
      this.lat = e.lngLat.lat;
      this.getLatLong();
    },
    geolocateError(control, positionError) {
      console.log(control, positionError);
    },
    geolocate(control, position) {
      this.lat = position.coords.latitude;
      this.lon = position.coords.longitude;
      this.getLatLong();
    }
  }
};
</script>
<style scoped>
.asteric {
  size: 10px;
  color: #a50000ad;
}
.img-responsive {
  width: 100%;
  max-width: 400px;
  height: auto;
}
.cityContainer {
  /* width: 80%; */
  border: solid 1px #a19c9c;
  padding: 5px;
  margin: 1px;
  background-color: #79bbb3;
  color: #615e5e;
  font-weight: bold;
  text-align: center;
}
.label {
  font-size: 30px;
  color: #a19c9c;
  font-weight: 500;
  align-content: center;
  margin-left: 5px;
}
.titleUser {
  color: #444444;
  size: 14px;
  font-weight: bold;
}
.emailUser {
  color: #757575;
  size: 12px;
  font-weight: 200;
  margin-top: -19px;
}
.bs {
  width: 40px;
  height: 40px;
  size: 80px;
  margin-right: -25px;
}
.userStatus {
  /* background-color: #d4d3d3; */
  /* width: 90%; */
  height: 50px;
}
.barStatus {
  margin-top: 45px;
  padding: 10px;
  /* background-color: #b9b3b3; */
  width: 100%;
  height: 90px;
}
.btnx {
  width: 45%;
}
.minLabel {
  font-weight: bold;
  font-size: 10px;
}
.totalCard {
  width: 90%;
  margin: auto;
  display: block;
  overflow-y: hidden;
  overflow-x: hidden;
  height: auto;
  max-height: 450px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0px 7px 20px 2px rgba(0, 0, 0, 0.05),
    0px 4px 18px rgba(0, 0, 0, 0.05);
}
.createButton {
  position: fixed;
  height: 60px !important;
  bottom: 30px;
  right: 30px;
  border-radius: 50% !important;
  width: 60px !important;
  font-size: 0.8vw;
  /* z-index: 9999999999999; */
  border-style: none;
  background-color: #06b815 !important;
  color: #e0e0e0;
  letter-spacing: 0.2vw;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.modal-position {
  position: absolute !important;
  right: 0 !important;
  margin: 0 !important;
}

.modal-dialog {
  width: 500px;
}

.modal-header {
  padding: 1rem 1rem 0 1rem !important;
}

.modal-body {
  padding: 0 1rem !important;
}

.modal-content {
  height: 100vh !important;
}

.modalContainer {
  background-color: #e0e0e0;
}
.modalItemList {
  width: 100%;
  height: 175px;
  overflow-y: auto;
  padding-bottom: 20px;
  /* background: #fff;
    box-shadow: 0 2.5px 13.5px 0 rgba(10, 10, 10, 0.15); */
  overflow-x: hidden;
  overflow-y: auto;
}

.loader-container {
  background: #fff;
  width: 400px;
  height: 300px;
  transition: 0.3s all ease;
  transform: scale(0);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.loader-show {
  transform: scale(1) !important;
}

.loader {
  width: 90%;
  height: 100%;
  position: absolute;
  /* z-index: 999999; */
  overflow: hidden;
  display: flex;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
}

.loader-text {
  font-family: "segoe ui";
  font-style: italic;
  font-size: 20px;
}
.btn-save {
  border: none;
  background: black;
  color: white;
  border-radius: 5px;
  display: block;
  margin: auto;
}

.generalContainer {
  /* width: 100%; */
  height: 100vh;
  overflow: hidden;
}
.topBar {
  /* width: 90vw; */
  height: 10.666667vh;
  background-color: #f3f3f3;
}
.containerProfile {
  width: 88%;
  height: 75%;
  background-color: transparent;
  margin-left: 1%;
  margin-top: 1%;
  overflow-y: auto;
  overflow-x: hidden;
}
.timerContainer {
  width: 85vw;
  height: 70vh;
  background-color: transparent;
  margin-left: 1vw;
  margin-top: 1vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: -40px;
}
.storeTitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  margin-top: 100px;
  font-size: 35px;
}
.storeFormInput {
  width: 100%;
  border-radius: 0px;
  border-style: none;
  background-color: transparent;
  border-bottom: 2px solid #1e1e1e;
}
.indicationBar {
  margin: 0px;
  width: 100vw;
  height: 5vh;
  background-color: #f3f3f3;
}
.daysButton {
  width: 100%;
  height: 45px;
  margin-top: 10px;
  border-style: none;
  border-radius: 10px;
  background-color: #1e1e1e;
  color: #e0e0e0;
}
.timeSelector {
  width: 100%;
  height: 45px;
  border-style: none;
  margin-top: 10px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 2px solid #1e1e1e;
  font-size: 1.2vw;
}
.inputPicture {
  width: 85%;
  height: 45px;
  border-style: none;
  float: left;
  margin: auto;
  display: block;
  height: 40px;
}
.alertDanger {
  background-color: #a50000ad;
  color: #ffffff;
  font-size: 16px;
  margin: 3px;
  border-radius: 5px;
  padding: 5px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
#map {
  width: 100%;
  height: 250px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #707070;
}
</style>
